<template>
  <b-overlay
    :variant="skin"
    :show="isloading"
    spinner-variant="primary"
    spinner-type="grow"
  >
    <b-row v-if="appointment.jobTypeSelected" class="pt-1">
      <b-col md="6" v-if="appointment.jobTypeSelected.available_for_events">
        <b-button
          :variant="isEvent ? 'secondary' : 'outline-secondary'"
          @click="getEvents()"
          class="mb-1"
          block
          :disabled="appointment.id > 0"
          >Evento</b-button
        >
      </b-col>

      <b-col md="6" v-if="appointment.jobTypeSelected.available_for_lesson">
        <b-button
          :variant="isLesson ? 'secondary' : 'outline-secondary'"
          :disabled="appointment.id > 0"
          @click="getLessons()"
          block
          >Aula</b-button
        >
      </b-col>

      <b-col cols="12" v-if="this.isEvent">
        <h5>Evento:</h5>
        <b-form-group>
          <v-select
            v-model="eventsSelected"
            :disabled="appointment.id > 0"
            :options="events"
            autocomplete="off"
            :clearable="false"
            @input="onSelectedEvents"
          />
        </b-form-group>

        <b-form-checkbox
          v-if="appointment.jobTypeSelected.available_for_events"
          v-model="isRecordedAllEvents"
          class="custom-control-info mb-1"
        >
          Evento Todo Gravado ?
        </b-form-checkbox>

        <b-form-checkbox
          v-model="isNeedSupportStudio"
          class="custom-control-info"
          v-if="!isRecordedAllEvents"
        >
          Necessita de estudio de apoio?
        </b-form-checkbox>
        <b-form-checkbox
          v-model="isHasRecording"
          class="custom-control-info mt-1"
          v-if="!isRecordedAllEvents"
        >
          Tem Gravações?
        </b-form-checkbox>
      </b-col>

      <b-col cols="12" v-if="this.isLesson">
        <h5>Aula:</h5>
        <b-form-group>
          <v-select
            :disabled="appointment.id > 0"
            v-model="lessonsSelected"
            :options="lessons"
            autocomplete="off"
            :clearable="false"
            @input="onSelectedLesson"
            @change="getLessons()"
          />
        </b-form-group>
        <validation-observer ref="productsRules">
          <b-form @submit.prevent>
            <b-form-group v-if="lessonsSelected && appointment.id == 0">
              <h5>Alterar duração:</h5>
              <validation-provider
                #default="{ errors }"
                name="Duração"
                rules="required"
                class="d-flex justify-content-start"
              >
                <flat-pickr
                  v-model="lessonsSelected.duration"
                  placeholder="Duração"
                  class="form-control"
                  :config="{
                    enableTime: true,
                    noCalendar: true,
                    dateFormat: 'H:i',
                    time_24hr: true,
                  }"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <div class="px-2">
                  <b-button
                    variant="info"
                    @click="validationForm(lessonsSelected)"
                  >
                    Alterar
                  </b-button>
                </div>
              </validation-provider>
            </b-form-group>
          </b-form>
        </validation-observer>
      </b-col>

      <!-- lessons -->
      <b-col
        v-if="
          this.appointment.jobTypeSelected.accept_recordings &&
          this.lessonsSelected &&
          this.isLesson
        "
        class="my-2"
        cols="12"
      >
        <b-col cols="12" class="p-0">
          <div class="d-flex">
            <b-form-checkbox
              :disabled="
                !(this.presential && this.remote) && // desabilitar seleção caso não tenha formato presencial e remoto
                appointment.jobTypeSelected.accept_recordings
              "
              :class="
                !isRecordedMaterial
                  ? 'custom-control-primary'
                  : 'custom-control-info'
              "
              v-model="isRecordedMaterial"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
            <span>Aceita material gravado ?</span>
          </div>
        </b-col>
      </b-col>

      <!-- <b-col
        class="my-25"
        v-if="isLesson && isRecordedMaterial && !isRecordPendency"
      >
        <b-form-group label="Gravações">
          <v-select
            v-model="recordingSelected"
            :options="recordingList"
            autocomplete="off"
            :clearable="false"
          />
        </b-form-group>
      </b-col> -->

      <b-col cols="12" v-if="isLesson && isRecordedMaterial">
        <div class="py-1 d-flex">
          <b-form-checkbox
            class="custom-control-warning"
            name="check-button"
            v-model="isRecordedMaterial"
            :disabled="isRecordedMaterial"
            switch
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
          <strong>Material Pendente ?</strong>
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col cols="12">
        <b-button variant="info" @click="onClickNext">
          <span class="mr-25 align-middle">Próximo</span>
        </b-button>
      </b-col>
    </b-row>
  </b-overlay>
</template>
<script>
import _appointmentService from "@/services/appointment-service";
import _projectsService from "@/services/projects-service";
import _lessonService from "@/services/lessons-service";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import useAppConfig from "@core/app-config/useAppConfig";
export default {
  setup() {
    const { skin } = useAppConfig();
    return {
      skin,
    };
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    required,
  },
  props: {
    appointment: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      isRecordedMaterial: false,
      isRecordedAllEvents: false,
      isloading: false,

      isEvent: false,
      events: [],
      eventsSelected: null,

      isLesson: false,
      lessons: [],
      lessonsSelected: null,
      isRecordPendency: false,

      recordingList: [],
      recordingSelected: null,

      eventsRecurrents: [],

      isNeedSupportStudio: false,
      isHasRecording: false,
      presential: false,
      remote: false,
    };
  },
  created() {
    if (this.appointment.jobTypeSelected) {
      if (this.appointment.jobTypeSelected.jobFormats.length <= 0) {
        // sem formatos, então obrigatorio ter material gravado.
        this.appointment.accept_recording = true; // obrigatorio selecionar gravação
        this.appointment.recording_pending = false; // não pode ser pendente de gravação
      } else {
        // reagendamento de evento
        if (this.appointment.id > 0 && this.appointment.type === 1) {
          this.isHasRecording = this.appointment.accept_recording;
        } else {
          // verificar formatos existentes
          this.presential = this.appointment.jobTypeSelected.jobFormats.some(
            (s) => s.formatType.id === this.$utils.enums.presential
          );

          this.remote = this.appointment.jobTypeSelected.jobFormats.some(
            (s) => s.formatType.id === this.$utils.enums.remote
          );

          let salaVip = this.appointment.jobTypeSelected.jobFormats.some(
            (s) => s.formatType.name.toLowerCase() === "sala vip"
          );
          // caso não exista presencial nem remoto na lista, deve ser informado gravação.
          this.appointment.accept_recording = !this.presential && !this.remote && !salaVip;
          if (this.appointment.accept_recording) {
            // obrigatorio selecionar gravação
            this.appointment.recording_pending = false; // não pode ser pendente de gravação
          }
        }
      }
    }

    //
    this.isRecordPendency = this.appointment.recording_pending;
    if (this.appointment.lessonsSelected || this.appointment.eventsSelected) {
      this.lessonsSelected = this.appointment.lessonsSelected;
      this.eventsSelected = this.appointment.eventsSelected;

      this.isLesson = this.appointment.type === 2;
      this.isEvent = this.appointment.type === 1;
      if (this.isLesson) {
        console.log("Payload => 285", this.appointment.accept_recording);
        this.isRecordedMaterial = this.appointment.accept_recording;
      } else {
        this.isRecordedAllEvents = this.appointment.recording_all_events;
        this.isNeedSupportStudio = this.appointment.is_support_studio;
        this.isHasRecording = this.appointment.accept_recording;
        console.log("Payload => 291", this.appointment.accept_recording);
      }
    }
    this.checkRecording();
  },
  methods: {
    validationForm(lessonsSelected) {
      this.$refs.productsRules.validate().then(async (success) => {
        if (success) {
          await this.changeDuration(lessonsSelected);
        }
      });
    },
    getEvents() {
      this.isEvent = false;
      this.isLesson = false;

      if (this.appointment.projectSelected) {
        this.isEvent = true;
        if (this.events.length <= 0) {
          this.isloading = true;
          _projectsService
            .showEvents(this.appointment.projectSelected.id)
            .then((res) => {
              if (res.content && res.content.length > 0) {
                this.events = res.content.map((m) => {
                  return {
                    label: m.name,
                    value: m.id,
                    ...m,
                  };
                });
              } else {
                this.$utils.toastError(
                  "Notificação",
                  "Nenhum EVENTO encontrado para esse projeto"
                );
              }
            })
            .catch((error) => this.$utils.toastError("Notificação", error))
            .finally(() => (this.isloading = false));
        }
      } else {
        this.$utils.toastError("Notificação", "Nenhum projeto selecionado.");
      }
    },
    getLessons() {
      this.isEvent = false;
      this.isLesson = false;

      if (this.appointment.projectSelected) {
        this.isLesson = true;
        this.isloading = true;
        _appointmentService
          .showLessons(this.appointment.projectSelected.id)
          .then((res) => {
            if (res.content && res.content.length > 0) {
              this.lessons = res.content.map((m) => {
                return {
                  label:
                    "Aula " +
                    m.order +
                    " - " +
                    m.name +
                    " - Duração: " +
                    m.duration,
                  value: m.id,
                  duration: m.duration,
                  ...m,
                };
              });
            } else {
              this.$utils.toastError(
                "Notificação",
                "Nenhum aula encontrada para esse projeto"
              );
            }
          })
          .catch((error) => this.$utils.toastError("Notificação", error))
          .finally(() => (this.isloading = false));
      }
    },
    onSelectedLesson() {
      //this.checkproject();
      this.checkRecording();
    },
    onSelectedEvents() {
      this.checkRecorrence();
    },
    checkRecorrence() {
      this.eventsRecurrents = [];

      if (this.eventsSelected.recurrence) {
        this.$swal({
          title: "Lembrete !!!",
          text: "Esse evento e recorrente",
          icon: "warning",
          confirmButtonText: "Estou ciente!",
          customClass: {
            confirmButton: "btn btn-info",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        });
      }
    },

    checkproject() {
      this.eventOriSelected = null;
      this.eventsRecurrents = [];
      this.remote_not_avaliable = false;
      this.remote_not_avaliable_checkbox = false;
      var duration = 0;
      if (!this.isEvent) {
        var lessonid = this.$utils.getValueSelected(this.lessonsSelected);
        duration = this.lessonsOri.filter((f) => f.id === lessonid)[0].duration;
        var lesson = this.lessonsOri.filter((f) => f.id === lessonid)[0];
        if (lesson) {
          lesson.course.teachers.forEach((t) => {
            if (!t.teacher.allows_homeoffice)
              this.remote_not_avaliable_checkbox = true;
          });

          if (
            this.remote_not_avaliable_checkbox &&
            this.remote &&
            !this.presential
          ) {
            this.remote_not_avaliable = true;
          }
        }
      } else {
        var eventid = this.$utils.getValueSelected(this.eventsSelected);
        this.eventOriSelected = this.eventsOri.filter(
          (f) => f.id === eventid
        )[0];
        duration = this.eventOriSelected.duration;

        var event = this.eventsOri.filter((f) => f.id === eventid)[0];
        if (event) {
          event.subEvents.forEach((s) => {
            s.subEventsTeachers.forEach((t) => {
              if (!t.teacher.allows_homeoffice)
                this.remote_not_avaliable_checkbox = true;
            });
          });

          if (
            this.remote_not_avaliable_checkbox &&
            this.remote &&
            !this.presential
          ) {
            this.remote_not_avaliable = true;
          }
        }

        // recorrencia
        if (this.eventOriSelected.recurrence) {
          // duplicar agendamentos
          for (
            let index = 0;
            index < this.eventOriSelected.recurrence_quantity.length;
            index++
          ) {
            this.eventsRecurrents.push({
              event: this.eventOriSelected,
              order: index,
            });
          }
          this.$swal({
            title: "Lembrete !!!",
            text: "Esse evento e recorrente",
            icon: "warning",
            confirmButtonText: "Estou ciente!",
            customClass: {
              confirmButton: "btn btn-info",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            buttonsStyling: false,
          });
        }
      }

      _appointmentService
        .showProjectsCheck(this.projectSelected.id, this.start_hour, duration)
        .then((res) => {
          if (res.content) {
            if (!res.content[0].valid) {
              this.$utils.toastError("PROJETO", res.content[0].remark);
              this.project_valid = false;
            } else {
              this.project_valid = true;
            }
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.isloading = false));
    },
    checkRecording() {
      this.isloading = true;
      let params = {
        company_id: this.appointment.projectSelected.company_id,
        teachers: [],
      };

      if (this.appointment.teachers)
        this.appointment.teachers.forEach((fe) =>
          params.teachers.push({ teacher_id: fe.teacher_id })
        );

      _appointmentService
        .showSubjectsByTeachers(params)
        .then((res) => {
          this.recordingList = res.content.map((m) => {
            return {
              label: m.subject,
              value: m.id,
            };
          });

          // carregar subjects lessons ja existentes
          const subjects = this.appointment.appointmentSubjects;
          if (subjects && subjects.length > 0) {
            // se for aula
            if (this.isLesson) {
              // apenas uma gravação por aula.
              this.recordingSelected = {
                label: subjects[0].mediaSubject.subject,
                value: subjects[0].media_file_subject_id,
              };

              // caso tenha nome já formatado na lista acima, alterar para a mesma.
              if (
                this.recordingList.some(
                  (s) => s.value === subjects[0].media_file_subject_id
                )
              ) {
                this.recordingSelected = this.recordingList.filter(
                  (f) => f.value === subjects[0].media_file_subject_id
                )[0];
              }
            } else {
              // se for evento
            }
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.isloading = false));
    },

    onclickRecurrenceNext() {
      if (this.eventsSelected.recurrence) {
        let start = this.appointment.start.split("T")[0];
        let hour = this.appointment.start.split("T")[1];

        let dataref = new Date(start);
        dataref.setDate(dataref.getDate() + 1);

        const qtd = this.eventsSelected.recurrence_quantity;
        for (let index = 0; index < qtd; index++) {
          // preencher lista de recorrência
          this.appointment.recurrenceItens.push({
            dataprevius: this.$utils.formatToDate(dataref),
            studiosSelected: null,
            event: {
              name: this.eventsSelected.name,
              id: this.eventsSelected.id,
            },
            isloading: false,
            status: false,
            ok: false,
            hour: hour,
          });

          // recorrencia mensal ou semanal
          switch (this.eventsSelected.frequency.toString()) {
            case "0": //mensal
              dataref.setMonth(dataref.getMonth() + 1);
              break;

            case "1": // semanal
              dataref.setDate(dataref.getDate() + 7);
              break;
          }
        }
      }
    },
    onClickNext() {
      // material gravado ou tem gravação
      this.appointment.accept_recording =
        this.isRecordedMaterial || this.isHasRecording;

      this.appointment.recurrenceItens = [];
      this.appointment.type = this.isLesson ? 2 : 1;
      this.appointment.recording_pending = false;
      this.appointment.lesson_id = null;
      this.appointment.event_id = null;
      this.appointment.dsLesson = "";
      this.appointment.dsEvent = "";
      this.appointment.duration = 0;

      this.appointment.lessonsSelected = null;
      this.appointment.eventsSelected = null;

      if (this.isLesson && this.lessonsSelected) {
        // avançar com aula
        this.appointment.recording_pending = this.isRecordedMaterial; //this.isRecordPendency; // agendamento pendente
        this.appointment.duration = this.lessonsSelected.duration;
        this.appointment.lesson_id = this.lessonsSelected.value;
        this.appointment.dsLesson = this.lessonsSelected.label;
        this.appointment.lessonsSelected = this.lessonsSelected;

        //
        this.appointment.appointmentSubjects = [];
        if (!this.appointment.recording_pending) {
          // material não e pendente.
          if (this.recordingSelected) {
            // escolheu uma gravação.
            this.appointment.appointmentSubjects.push({
              media_file_subject_id: this.recordingSelected.value,
            });
          }
        } else {
          // escolheu material pendente
        }

        this.nextStep();
      } else if (this.isEvent && this.eventsSelected) {
        this.appointment.recording_all_events = this.isRecordedAllEvents;
        this.appointment.recording_pending = this.isRecordedAllEvents;
        this.appointment.is_support_studio = this.isNeedSupportStudio;

        this.appointment.duration = this.eventsSelected.duration;
        this.appointment.eventsSelected = this.eventsSelected;
        this.appointment.event_id = this.eventsSelected.value;
        this.appointment.dsEvent = this.eventsSelected.label;

        // descritivos display para o usuario.
        if (this.isRecordedAllEvents) {
          this.appointment.dsEvent += " / Todo Gravado";
        }

        if (this.isHasRecording && this.isNeedSupportStudio) {
          this.appointment.dsEvent += " / Com Gravação e Estúdio de apoio";
        } else {
          if (this.isHasRecording) {
            this.appointment.dsEvent += " / Com Gravação";
          }

          if (this.isNeedSupportStudio) {
            this.appointment.dsEvent += " / Com estúdio de apoio";
          }
        }

        // se evento for todo gravado
        // não considerar opção de 'tem gravação?'
        // não considerar opção de 'estudio de apoio'
        if (this.appointment.recording_all_events) {
          this.appointment.is_support_studio = false;
          this.appointment.accept_recording = false;

          // subjects
          this.appointment.appointmentSubjects = [];
          this.appointment.appointmentSubjects =
            this.appointment.eventsSelected.subEvents.map((m) => {
              return {
                subevent_id: m.id,
                recording_pending: true,
              };
            });
        }
        this.onclickRecurrenceNext();
        this.nextStep();
      }
    },
    nextStep() {
      this.$emit("next", this.appointment);
    },
    async changeDuration(lesson) {
      this.$swal({
        title: "Alteração da duração",
        text: "A duração será alterada mesmo se não completar a marcação do agendamento, você deseja continuar?",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero continuar!",
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "btn btn-info",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          if (result.isConfirmed) {
            await this.saveDuration(lesson);
            await this.getLessons();
          }
        }
      });
    },
    async saveDuration(lesson) {
      this.isloading = true;
      const payload = [
        {
          path: "/duration",
          op: "replace",
          value: lesson.duration,
        },
      ];
      await _lessonService
        .saveDuration(lesson.id, payload)
        .then((res) => {
          if (res.content) {
            let newLabel = lesson.label.split("-");
            newLabel[newLabel.length - 1] = ` Duração: ${res.content.duration}`;
            newLabel = newLabel.join("-");
            this.lessonsSelected.label = newLabel;
            this.$utils.toast("Notificação", "Duração alterada com sucesso.");
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally((this.isloading = false));
    },
  },
};
</script>
<style scoped>
.form-control {
  padding: 0.438rem 1.3rem;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  width: 80px;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #fff;
}
.justify-content-between {
  justify-content: space-evenly;
}
</style>