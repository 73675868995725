var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.appointment)?_c('div',[_c('b-row',{staticClass:"mt-1"},[(!_vm.projectSelected)?_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('small',{staticClass:"text-muted"},[_vm._v(" Escolha um projeto para o "+_vm._s(_vm.appointment.id > 0 ? "Reagendamento" : "Agendamento")+". ")])]):_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0 text-uppercase"},[_vm._v(" "+_vm._s(_vm.projectSelected.name)+" ")]),(_vm.projectSelected.company)?_c('div',[_c('b-badge',{style:(("background: " + (_vm.projectSelected.companycolor)))},[_c('feather-icon',{attrs:{"icon":"HomeIcon","size":"21"}})],1),_c('small',[_vm._v(" "+_vm._s(_vm.projectSelected.company)+" ")])],1):_vm._e()]),(_vm.appointment.id <= 0)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('b-overlay',{attrs:{"variant":_vm.skin,"show":_vm.isloading,"spinner-variant":"primary","spinner-type":"grow"}},[_c('vue-autosuggest',{attrs:{"suggestions":_vm.projects,"input-props":_vm.inputProps,"section-configs":_vm.autoSugestsConfigs},on:{"input":_vm.getAutoCompleteProjects},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var suggestion = ref.suggestion;
return [(suggestion.item.valid)?_c('div',[_c('h5',{staticClass:"mb-0 text-uppercase"},[_vm._v(" "+_vm._s(suggestion.item.name)+" ")]),(suggestion.item.company)?_c('div',[_c('b-badge',{style:(("background: " + (suggestion.item.companycolor)))},[_c('feather-icon',{attrs:{"icon":"HomeIcon","size":"21"}})],1),_c('small',[_vm._v(" "+_vm._s(suggestion.item.company)+" ")])],1):_vm._e()]):_vm._e()]}}],null,false,165582454),model:{value:(_vm.queryProject),callback:function ($$v) {_vm.queryProject=$$v},expression:"queryProject"}})],1)],1)],1):_vm._e()],1),_c('b-row',{attrs:{"align-v":"center"}},[_c('b-col',{staticClass:"my-1",attrs:{"cols":"10","md":"6"}},[_c('v-select',{attrs:{"options":_vm.jobTypelist},model:{value:(_vm.jobTypeSelected),callback:function ($$v) {_vm.jobTypeSelected=$$v},expression:"jobTypeSelected"}})],1),(_vm.jobTypeSelected)?_c('b-col',{attrs:{"cols":"2","md":"1"}},[_c('feather-icon',{attrs:{"size":"20","icon":"InfoIcon","id":"tooltip-button-2"}}),_c('b-tooltip',{attrs:{"target":"tooltip-button-2","placement":"right","triggers":"hover"}},[(
            _vm.jobTypeSelected.available_for_events ||
            _vm.jobTypeSelected.available_for_lesson
          )?_c('div',{staticClass:"tooltips-itens"},[_vm._v(" Disponível para: "),(_vm.jobTypeSelected.available_for_events)?_c('b-badge',{attrs:{"variant":"light-warning"}},[_vm._v("Eventos")]):_vm._e(),(_vm.jobTypeSelected.available_for_lesson)?_c('b-badge',{attrs:{"variant":"light-warning"}},[_vm._v("Aulas")]):_vm._e()],1):_vm._e(),(
            _vm.jobTypeSelected.jobFormats &&
            _vm.jobTypeSelected.jobFormats.length > 0
          )?_c('div',{staticClass:"tooltips-itens"},[_vm._v(" Tipos de Formato: "),_vm._l((_vm.jobTypeSelected.jobFormats),function(jf,index){return _c('b-badge',{key:index,staticStyle:{"margin-right":"3px"},attrs:{"variant":"light-success"}},[_vm._v(_vm._s(jf.formatType.name))])})],2):_vm._e(),(
            _vm.jobTypeSelected.jobProducts &&
            _vm.jobTypeSelected.jobProducts.length > 0
          )?_c('div',{staticClass:"tooltips-itens"},[_vm._v(" Tipos de Produto: "),_vm._l((_vm.jobTypeSelected.jobProducts),function(jp,index){return _c('b-badge',{key:index,staticStyle:{"margin-right":"3px"},attrs:{"variant":"light-info"}},[_vm._v(_vm._s(jp.products.name))])})],2):_vm._e(),(
            _vm.jobTypeSelected.jobTransmission &&
            _vm.jobTypeSelected.jobTransmission.length > 0
          )?_c('div',{staticClass:"tooltips-itens"},[_vm._v(" Tipos de Transmissão: "),_vm._l((_vm.jobTypeSelected.jobTransmission),function(jt,index){return _c('b-badge',{key:index,staticStyle:{"margin-right":"3px"},attrs:{"variant":"light-danger"}},[_vm._v(" "+_vm._s(jt.transmissionType.name)+" - "+_vm._s(jt.amount)+" ")])})],2):_vm._e(),_c('div',{staticClass:"tooltips-itens"},[_vm._v(" Obrigatório Upload Material: "),_c('b',[_vm._v(" "+_vm._s(_vm.jobTypeSelected.required_upload_material ? "Sim" : "Não")+" ")])]),_c('div',{staticClass:"tooltips-itens"},[_vm._v(" Aceita extrapolação de horário: "),_c('b',[_vm._v(" "+_vm._s(_vm.jobTypeSelected.accept_time_extrapolation ? "Sim" : "Não")+" ")])]),(_vm.jobTypeSelected.jobTypePeriod)?_c('div',{staticClass:"tooltips-itens"},[_vm._v(" Período: "),_c('b',[_vm._v(" "+_vm._s(_vm.jobTypeSelected.jobTypePeriod.name)+" ")])]):_vm._e(),_c('div',{staticClass:"tooltips-itens"},[_vm._v(" Aceita material gravado: "),_c('b',[_vm._v(" "+_vm._s(_vm.jobTypeSelected.accept_recordings ? "Sim" : "Não")+" ")])]),_c('div',{staticClass:"tooltips-itens"},[_vm._v(" Quantidade de operadores necessários: "),_c('b',[_vm._v(" "+_vm._s(_vm.jobTypeSelected.number_of_operators)+" ")])]),_c('div',{staticClass:"tooltips-itens"},[_vm._v(" Tempo de preparação: "),_c('b',[_vm._v(" "+_vm._s(_vm.jobTypeSelected.preparation_time_minute)+" (Minutos) ")])]),_c('div',{staticClass:"tooltips-itens"},[_vm._v(" Mínimo de estúdio: "),_c('b',[_vm._v(" "+_vm._s(_vm.jobTypeSelected.min_studios)+" ")])])])],1):_vm._e()],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"info"},on:{"click":_vm.onClickNext}},[_c('span',{staticClass:"mr-25 align-middle"},[_vm._v("Próximo")])])],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }