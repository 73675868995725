<template>
  <div class="mt-1">
    <b-row>
      <b-col md="6">
        <b-form-group>
          <b-overlay
            :variant="skin"
            :show="isloading"
            spinner-variant="primary"
            spinner-type="grow"
          >
            <vue-autosuggest
              v-model="queryStudio"
              :suggestions="studios"
              :input-props="inputStudiosProps"
              :section-configs="autoSugestsConfigs"
              @input="getAutoCompleteStudios"
              v-if="!homeoffice"
              class="mt-1"
            >
              <template slot-scope="{ suggestion }">
                <h5 class="mb-0">{{ suggestion.item.name }}</h5>
                <small v-if="suggestion.item.place">
                  {{ suggestion.item.place.name }}
                </small>
              </template>
            </vue-autosuggest>

            <div class="d-flex py-1" v-if="remote">
              <b-form-checkbox
                class="custom-control-success"
                name="check-button"
                v-model="homeoffice"
                switch
                v-if="remote && !remote_not_avaliable_checkbox"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
              <strong v-if="remote && !remote_not_avaliable_checkbox"
                >Apenas Estúdio HomeOffice ?</strong
              >
            </div>

            <!-- estudios marcados -->
            <div v-if="studiosAdded && studiosAdded.length > 0 && !homeoffice">
              <b-row
                class="mb-1 py-1"
                v-for="(_stdo, index) in studiosAdded"
                :key="index"
              >
                <b-col cols="10" class="d-flex align-items-center">
                  <b-button
                    variant="outline-danger"
                    class="mr-1"
                    @click="removeStudio(_stdo)"
                    size="sm"
                  >
                    <feather-icon icon="XIcon" class="mr-25" />
                    <span>Remover</span>
                  </b-button>
                  <div>
                    <h5 class="mb-0">{{ _stdo.name }}</h5>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-overlay>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col cols="12">
        <b-button variant="info" @click="onClickNext">
          <span class="mr-25 align-middle">Próximo</span>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import _appointmentService from "@/services/appointment-service";
import useAppConfig from "@core/app-config/useAppConfig";
export default {
  setup() {
    const { skin } = useAppConfig();
    return {
      skin,
    };
  },
  props: {
    appointment: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      isloading: false,
      presential: false,
      remote: false,
      homeoffice: false,

      remote_not_avaliable_checkbox: false,
      remote_not_avaliable: false,

      inputStudiosProps: {
        id: "autostudios__input_ajax",
        placeholder: "Digite o nome do estúdio ou local",
        class: "form-control",
        name: "ajax",
      },
      autoSugestsConfigs: {
        studios: {
          limit: 20,
          onSelected: (selected) => {
            this.queryStudio = null;
            let _add = true;
            if (this.studiosAdded.length > 0) {
              if (
                this.studiosAdded.length >= process.env.VUE_APP_MAX_QTD_STUDIOS
              ) {
                _add = false;
                this.$utils.toastError(
                  "OPS!!!",
                  "Não e possível adicionar mais de " +
                    process.env.VUE_APP_MAX_QTD_STUDIOS +
                    " estúdios."
                );
              }
            }

            if (
              _add &&
              !this.studiosAdded.some((s) => s.id == selected.item.id)
            ) {
              this.studiosAdded.push(selected.item);
            }
          },
        },
      },
      queryStudio: "",
      studioSelected: null,
      studiosAdded: [],
      studios: [],
    };
  },
  created() {
    if (this.appointment.jobTypeSelected) {
      if (this.appointment.jobTypeSelected.jobFormats.length <= 0) {
        // sem formatos, então obrigatorio ter material gravado.
        this.isRecordedMaterial = true;
      } else {
        // verificar formatos existentes
        this.presential = this.appointment.jobTypeSelected.jobFormats.some(
          (s) => s.formatType.id === this.$utils.enums.presential
        );

        this.remote = this.appointment.jobTypeSelected.jobFormats.some(
          (s) => s.formatType.id === this.$utils.enums.remote
        );
      }
    }

    // course
    if (
      this.appointment.lessonsSelected &&
      this.appointment.lessonsSelected.course
    ) {
      this.remote_not_avaliable_checkbox =
        this.appointment.lessonsSelected.course.teachers.some(
          (t) => !t.teacher.allows_homeoffice
        );

      this.remote_not_avaliable =
        this.remote_not_avaliable_checkbox && !this.presential && this.remote;
    } else {
      // se tiver estudio de apoio não aparecer a opção "Apenas Estúdio HomeOffice"
      this.remote = !this.appointment.is_support_studio;
    }

    // popular estudio atual
    this.homeoffice = this.appointment.is_home_office;

    // tiver estudio já trazer preenchido.
    if (this.appointment.studios.length > 0) {
      this.studiosAdded = this.appointment.studios
        .filter((f) => f.main)
        .map((m) => {
          return {
            id: m.studio_id,
            name: m.name,
            main: m.main,
          };
        });
    }
  },
  methods: {
    getAutoCompleteStudios() {
      if (this.queryStudio && this.queryStudio.length >= 3) {
        setTimeout(() => {
          if (!this.isloading) {
            this.isloading = true;

            // considerar duration_pending
            const _duration = this.appointment.duration_pending
              ? this.appointment.duration_pending
              : this.appointment.duration;

            _appointmentService
              .showAllStudios(
                this.queryStudio,
                this.appointment.start,
                _duration,
                this.homeoffice,
                this.appointment.id,
                this.appointment.event_id,
                this.presential,
                this.appointment.jobTypeSelected.id,
                this.appointment.lesson_id,
                false,
                true //nocache
              )
              .then((res) => {
                this.studios = [{ name: "studios", data: res.content }];
              })
              .catch((error) => this.$utils.toastError("Notificação", error))
              .finally(() => (this.isloading = false));
          }
        }, 500);
      }
    },
    removeStudio(_item) {
      this.studiosAdded = this.studiosAdded.filter((f) => f.id !== _item.id);
    },

    onClickNext() {
      this.appointment.is_home_office = this.homeoffice;

      this.appointment.studios = this.appointment.studios.filter(
        (f) => !f.main
      );
      this.studiosAdded.forEach((_itemApo) => {
        this.appointment.studios.push({
          studio_id: _itemApo.id,
          name: _itemApo.name,
          main: true,
        });
      });

      this.$emit("next", this.appointment);
    },
  },
};
</script>