import HttpService from '../HttpService'

const _http = new HttpService()
export default {
  show: (page, teachers, companies, projects, completelist, appointment_id, order_by, asc) => {
    let _parans = `?page=${page - 1}`;
      if (appointment_id)
        _parans += `&data.appointment_id=${appointment_id}`;
      if (teachers)
        teachers.forEach(e => {
            _parans += `&data.teachers=${e}`;
        });
      if (companies)
        companies.forEach(e => {
            _parans += `&data.companies=${e}`;
        });
      if (projects)
        projects.forEach(e => {
            _parans += `&data.projects=${e}`;
        });
      if (completelist)
      completelist.forEach(e => {
            _parans += `&data.completedList=${e}`;
        });
      if (order_by)
        _parans += `&order_by=${order_by}`;
      if (asc)
        _parans += '&asc';
    return _http.get(`/api/audio-visual/recording-media-pending/filter${_parans}`)
  },
  findByIdDetails: (id) => {
    return _http.get(`/api/audio-visual/recording-media-pending/by-id-details/${id}`)
  },
  find: (id) => {
    return _http.get(`/api/audio-visual/recording-media-pending/${id}`)
  },
  create: (payload) => {
    return _http.post('/api/audio-visual/recording-media-pending', payload)
  },
  update: (payload) => {
    return _http.put('/api/audio-visual/recording-media-pending', payload)
  },
  changeCompleted: (payload) => {
    return _http.put('/api/audio-visual/recording-media-pending/change-completed', payload)
  },
  delete: (id) => {
    return _http.delete(`/api/audio-visual/recording-media-pending/${id}`)
  },
  status: () => {
    return _http.get(`/api/audio-visual/recording-media-pending/status`)
  },
}