<template>
  <div>
    <b-button
      size="sm"
      class="btn-icon"
      variant="gradient-info"
      @click="newSupportStudio()"
    >
      <feather-icon icon="PlusIcon" />
    </b-button>
    <hr />

    <b-row
      v-for="(item, iitem) in studiosSupport"
      :id="'blb-' + item.id"
      :key="iitem"
    >
      <b-col cols="12" md="6">
        <b-form-group label="Sub-Evento">
          <v-select
            v-model="item.subeventsSelected"
            :options="subevents"
            autocomplete="off"
            :clearable="false"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="4">
        <b-form-group label="Estúdios">
          <v-select
            v-model="item.studiosSelected"
            :options="supportstudios"
            :disabled="!item.subeventsSelected"
            searchable
            @search="
              (term) => {
                fetchStudiosApoio(term, item);
              }
            "
            :loading="isloadingStudioApoio"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="1">
        <b-form-group label="-">
          <b-button
            variant="outline-danger"
            @click="removeStudioSupport(item)"
            size="sm"
          >
            <feather-icon size="16" icon="Trash2Icon" />
          </b-button>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col cols="12">
        <b-button variant="info" @click="onClickNext">
          <span class="mr-25 align-middle">Próximo</span>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import _appointmentService from "@/services/appointment-service";
export default {
  props: {
    appointment: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      isloadingStudioApoio: false,
      isloading: false,
      subeventsSelecteds: [],
      supportstudios: [],
      studiosSupport: [],
      subevents: [],
    };
  },
  created() {
    if (this.appointment.eventsSelected.subEvents) {
      this.subevents = this.appointment.eventsSelected.subEvents.map((m) => {
        if (
          this.appointment.appointmentSubjects &&
          this.appointment.appointmentSubjects.length > 0
        ) {
          // não liberar subeventos já preenchidos na gravação
          if (
            !this.appointment.appointmentSubjects.some(
              (s) => s.subevent_id === m.id
            )
          ) {
            return {
              duration: m.duration,
              label: m.name,
              value: m.id,
            };
          }
        } else {
          return {
            duration: m.duration,
            label: m.name,
            value: m.id,
          };
        }
      });

      // validar objetos nulos
      if (this.subevents && this.subevents.length > 0) {
        this.subevents = this.subevents.filter((f) => f);
      }
    }

    if (this.appointment.studios.length > 0) {
      this.studiosSupport = this.appointment.studios
        .filter((f) => !f.main)
        .map((m) => {
          const sub = this.appointment.eventsSelected.subEvents.filter(
            (f) => f.id === m.subevent_id
          )[0];
          return {
            studiosSelected: { label: m.name, value: m.studio_id },
            subeventsSelected: { label: sub.name, value: sub.id },
            main: false,
            id: m.id,
          };
        });
    }
  },
  methods: {
    newSupportStudio() {
      // validar já foi preenchido campos anteriores
      if (this.studiosSupport.some((s) => s.subeventsSelected == null)) {
        this.$utils.toastError("OPS!!!", "Favor Preencher Sub evento acima");
        return;
      }

      if (this.studiosSupport.some((s) => s.studiosSelected == null)) {
        this.$utils.toastError("OPS!!!", "Favor Preencher Estudio acima");
        return;
      }

      // Studios
      let studioaux = [];
      this.supportstudios.forEach((_ss) => {
        if (
          !this.studiosSupport.some(
            (s) => s.studiosSelected.value === _ss.value
          )
        ) {
          studioaux.push(_ss);
        }
      });

      this.studiosSupport.forEach((_ss) => {
        //subeventos adicionados
        if (_ss.subeventsSelected) {
          this.subeventsSelecteds.push(_ss.subeventsSelected);
        }
      });

      // sub-events não duplicar
      let subaux = [];
      this.subevents.forEach((_se) => {
        if (!this.subeventsSelecteds.some((s) => s.value === _se.value)) {
          subaux.push(_se);
        }
      });

      if (subaux.length <= 0) {
        this.$utils.toastError("OPS!!!", "Esse e o último sub-evento");
        return;
      } else {
        this.subevents = subaux;
      } // FIM sub-events
      this.supportstudios = [];
      this.studiosSupport.push({
        subeventsSelected: null,
        studiosSelected: null,
        id: this.$utils.getGuidId(),
      });
    },
    removeStudioSupport(_item) {
      // devolve para a lista os evento
      this.subeventsSelecteds = [];
      this.studiosSupport.forEach((sr) => {
        let _sub = sr.subeventsSelected;
        if (_sub && !this.subevents.some((s) => s.value === _sub.value)) {
          this.subevents.push({ ..._sub });
        }
      });

      // devolve o studio para lista
      if (_item.studiosSelected) {
        if (
          !this.supportstudios.some(
            (s) => s.value === _item.studiosSelected.value
          )
        ) {
          this.supportstudios.push({
            ..._item.studiosSelected,
          });
        }
      }

      this.studiosSupport = this.studiosSupport.filter(
        (f) => f.id !== _item.id
      );
    },
    fetchStudiosApoio(_term, _item) {
      if (_term && _term.length >= 3) {
        setTimeout(() => {
          if (!this.isloadingStudioApoio) {
            var eventid = this.appointment.eventsSelected.value;
            var subevent_id = _item.subeventsSelected.value;

            this.isloadingStudioApoio = true;
            _appointmentService
              .showAllStudiosApoio(
                _term,
                this.appointment.start,
                eventid,
                subevent_id,
                0,
                this.appointment.jobTypeSelected.id
              )
              .then((res) => {
                if (res.content) {
                  let _stds = [];
                  res.content.forEach((_item) => {
                    // nao pode adicionar o studio principal
                    // pode adicionar studio ja adicionado em algum subevento em tempo de execução.
                    if (
                      !this.appointment.studios.some(
                        (s) => s.main && s.studio_id === _item.id
                      )
                    ) {
                      _stds.push(_item);
                    }
                  });

                  this.supportstudios = _stds.map((m) => {
                    return {
                      label: m.name,
                      value: m.id,
                    };
                  });
                }
              })
              .catch((error) => this.$utils.toastError("Notificação", error))
              .finally(() => (this.isloadingStudioApoio = false));
          }
        }, 500);
      }
    },
    onClickNext() {
      // limpar estudios de apoio
      this.appointment.studios = this.appointment.studios.filter((f) => f.main);

      // popular estudio de apoio
      if (this.studiosSupport && this.studiosSupport.length > 0) {
        // montar lista
        this.studiosSupport.forEach((_item) => {
          if (_item.studiosSelected) {
            this.appointment.studios.push({
              subevent_id: _item.subeventsSelected.value,
              studio_id: _item.studiosSelected.value,
              name: _item.studiosSelected.label,
              main: false,
            });
          }
        });
      }
      this.$emit("next", this.appointment);
    },
  },
};
</script>