var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex mt-1"},[_c('b-button',{staticClass:"mb-1",attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.addLink()}}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}}),_vm._v(" Link ")],1)],1),_c('hr'),_vm._l((_vm.links),function(ll,index){return _c('b-row',{key:index,staticClass:"mb-1"},[_c('b-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"options":_vm.jobTypeTransmissionList,"autocomplete":"off","clearable":false,"placeholder":"Tipo de Transmissão"},model:{value:(ll.jobTypeTransmissionSelected),callback:function ($$v) {_vm.$set(ll, "jobTypeTransmissionSelected", $$v)},expression:"ll.jobTypeTransmissionSelected"}})],1),_c('b-col',{attrs:{"cols":"2"}},[(_vm.companies.length > 0)?_c('v-select',{attrs:{"options":index === 0 ? ll.companies : _vm.companies,"autocomplete":"off","clearable":false,"placeholder":"Empresas"},model:{value:(ll.companieSelected),callback:function ($$v) {_vm.$set(ll, "companieSelected", $$v)},expression:"ll.companieSelected"}}):_vm._e()],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-input',{attrs:{"placeholder":ll.jobTypeTransmissionSelected &&
          ll.jobTypeTransmissionSelected.label === 'Youtube' &&
          ll.companieSelected &&
          ll.companieSelected.enable_youtube_api
            ? 'Geração link automática'
            : 'Informe a URL',"autocomplete":"off","disabled":ll.jobTypeTransmissionSelected &&
          ll.jobTypeTransmissionSelected.label === 'Youtube' &&
          ll.companieSelected &&
          ll.companieSelected.enable_youtube_api},model:{value:(ll.url),callback:function ($$v) {_vm.$set(ll, "url", $$v)},expression:"ll.url"}})],1),_c('b-col',{attrs:{"cols":"2"}},[(_vm.companies.some(function (s) { return s.enable_youtube_api; }))?_c('b-form-input',{attrs:{"placeholder":"Título","autocomplete":"off"},model:{value:(ll.url_title),callback:function ($$v) {_vm.$set(ll, "url_title", $$v)},expression:"ll.url_title"}}):_vm._e()],1),_c('b-col',{attrs:{"cols":"2"}},[(_vm.statusVisibilities.length > 0)?_c('v-select',{attrs:{"options":_vm.statusVisibilities,"autocomplete":"off","clearable":false,"placeholder":"Status pós transmissão"},model:{value:(ll.statusVisibilitySelected),callback:function ($$v) {_vm.$set(ll, "statusVisibilitySelected", $$v)},expression:"ll.statusVisibilitySelected"}}):_vm._e()],1),_c('b-col',{attrs:{"cols":"1"}},[_c('b-button',{staticClass:"btn-icon",staticStyle:{"float":"right"},attrs:{"variant":"danger"},on:{"click":function($event){return _vm.removeLink(ll)}}},[_c('feather-icon',{attrs:{"size":"20","icon":"Trash2Icon"}})],1)],1)],1)}),_c('b-row',{staticClass:"mt-3"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"info"},on:{"click":_vm.onClickNext}},[_c('span',{staticClass:"mr-25 align-middle"},[_vm._v("Próximo")])])],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }