<template>
  <b-overlay :variant="skin" :show="isloading" spinner-variant="primary" spinner-type="grow">
    <b-card v-if="appointment_active && !isloading">
      <app-timeline>
        <div v-for="(item, i) in steps" :key="i">
          <app-timeline-item
            :icon="item.ok ? 'CheckIcon' : item.icon"
            :variant="
              item.ok
                ? 'success'
                : item.index === step_current
                ? 'warning'
                : 'primary'
            "
            class="cursor-pointer mb-1"
            v-if="item.visible"
          >
            <!-- TITULO -->
            <transition :name="step_transition">
              <strong v-if="item.ok" @click="setStep(item)">{{
                item.display
              }}</strong>
              <span v-else>{{ item.name }}</span>
            </transition>

            <!-- STEPS -->

            <!-- Data e hora -->
            <transition :name="step_transition">
              <step-date-hour
                v-if="stepActive(item, 0)"
                :appointment="appointmentDTO"
                @next="(res) => nexthourDate(res, item)"
              />
            </transition>

            <!-- Projeto e jobType -->
            <transition :name="step_transition">
              <step-project
                v-if="stepActive(item, 1)"
                :appointment="appointmentDTO"
                @next="(res) => nextProject(res, item)"
              />
            </transition>

            <!-- Evento / Aula -->
            <transition :name="step_transition">
              <step-event-lesson
                v-if="stepActive(item, 2)"
                :appointment="appointmentDTO"
                @next="(res) => nextEventOrLesson(res, item)"
              />
            </transition>

            <!-- SubEvents Orders -->
            <transition :name="step_transition">
              <step-subevent-order
                v-if="stepActive(item, 3)"
                :appointment="appointmentDTO"
                @next="(res) => nextsubeventOrders(res, item)"
              />
            </transition>

            <!-- Estúdio -->
            <transition :name="step_transition">
              <step-studio
                v-if="stepActive(item, 4)"
                :appointment="appointmentDTO"
                @next="(res) => nextStudio(res, item)"
              />
            </transition>

            <!-- Estúdio Apoio -->
            <transition :name="step_transition">
              <step-studio-support
                v-if="stepActive(item, 5)"
                :appointment="appointmentDTO"
                @next="(res) => nextStudioSupport(res, item)"
              />
            </transition>

            <!-- Gravações -->
            <transition :name="step_transition">
              <step-recording
                v-if="stepActive(item, 6)"
                :appointment="appointmentDTO"
                @next="(res) => nextRecording(res, item)"
              />
            </transition>

            <!-- Transmissões -->
            <transition :name="step_transition">
              <step-transmission
                v-if="stepActive(item, 7)"
                :appointment="appointmentDTO"
                @next="(res) => nextTransmission(res, item)"
              />
            </transition>

            <!-- produtos -->
            <transition :name="step_transition">
              <div v-if="stepActive(item, 8)">
                <step-products
                  :appointment="appointmentDTO"
                  @next="(res) => nextProducts(res, item)"
                />
              </div>
            </transition>

            <!-- Agendamento opcionais -->
            <transition :name="step_transition">
              <div v-if="stepActive(item, 9)">
                <step-details
                  :appointment="appointmentDTO"
                  @next="(res) => nextDetails(item)"
                />
              </div>
            </transition>

            <!-- Recorrências -->
            <transition :name="step_transition">
              <div v-if="stepActive(item, 10)">
                <step-recurrence :appointment="appointmentDTO" :steps="steps" />
              </div>
            </transition>
          </app-timeline-item>
        </div>
      </app-timeline>

      <!-- Botão finalizar -->
      <b-row
        class="mt-3"
        v-if="
          !this.steps[10].visible && // Em caso de recorrência o botão processar vai ser outro dentro de
          (this.steps[this.steps.length - 2].index === this.step_current ||
            this.steps.filter((f) => f.visible && !f.ok).length === 0)
        "
      >
        <b-col cols="12">
          <b-button variant="info" @click="onClickSubmit">
            <span class="mr-25 align-middle">{{
              appointmentDTO.id > 0
                ? "Confirmar Reagendamento"
                : "Confirmar Agendamento"
            }}</span>
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import useAppConfig from "@core/app-config/useAppConfig";
import _projectsService from "@/services/projects-service";
import _studiosService from "@/services/studios-service";
import _appointmentService from "@/services/appointment-service";
import _jobTypeService from "@/services/audiovisual/job-type-service";
import _companyService from "@/services/company-service";
import _recordingMediaPending from "@/services/audiovisual/recording-pending";
import _appointmentRequests from "@/services/audiovisual/appointment-requests";

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BCalendar,
  BFormRadio,
  BFormDatepicker,
} from "bootstrap-vue";

// novo
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";

import dateAndHour from "./steps/dateAndHour.vue";
import projects from "./steps/project.vue";
import eventOrLesson from "./steps/eventOrLesson.vue";
import studio from "./steps/studio.vue";
import transmission from "./steps/transmissions.vue";
import details from "./steps/details.vue";
import recordings from "./steps/recordings.vue";
import studioSupport from "./steps/studioSupport.vue";
import products from "./steps/products.vue";
import subEventsOrder from "./steps/subeventOrders.vue";
import recurrence from "./steps/recurrence.vue";

export default {
  setup() {
    const { skin } = useAppConfig();
    return {
      skin,
    };
  },
  components: {
    AppTimeline,
    AppTimelineItem,
    "step-date-hour": dateAndHour,
    "step-project": projects,
    "step-event-lesson": eventOrLesson,
    "step-studio": studio,
    "step-transmission": transmission,
    "step-details": details,
    "step-recording": recordings,
    "step-studio-support": studioSupport,
    "step-products": products,
    "step-subevent-order": subEventsOrder,
    "step-recurrence": recurrence,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BCalendar,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormRadio,
    BFormDatepicker,
  },
  data() {
    return {
      step_transition: "fade-bottom",
      step_current: 0,
      steps: [
        {
          index: 0,
          display: "",
          name: "Data e hora",
          icon: "ClockIcon",
          ok: false,
          visible: true,
        },
        {
          index: 1,
          display: "",
          name: "Projetos e Tipo de Agendamento",
          icon: "ClipboardIcon",
          ok: false,
          visible: true,
        },
        {
          index: 2,
          display: "",
          name: "Evento / Aula",
          icon: "CalendarIcon",
          ok: false,
          visible: true,
        },
        {
          index: 3,
          display: "",
          name: "Ordenar subEventos",
          icon: "SlidersIcon",
          ok: false,
          visible: false,
        },
        {
          index: 4,
          display: "",
          name: "Estúdios",
          icon: "HeadphonesIcon",
          ok: false,
          visible: false,
        },
        {
          index: 5,
          display: "",
          name: "Estúdio de Apoio",
          icon: "CastIcon",
          ok: false,
          visible: false,
        },
        {
          index: 6,
          display: "",
          name: "Gravações",
          icon: "FastForwardIcon",
          ok: false,
          visible: false,
        },
        {
          index: 7,
          display: "",
          name: "Transmissões",
          icon: "LinkIcon",
          ok: false,
          visible: true,
        },
        {
          index: 8,
          display: "",
          name: "Produtos",
          icon: "CastIcon",
          ok: false,
          visible: false,
        },
        {
          index: 9,
          display: "",
          name: "Agendamento",
          icon: "ThumbsUpIcon",
          ok: false,
          visible: true,
        },
        {
          index: 10,
          display: "",
          name: "Recorrência",
          icon: "RepeatIcon",
          ok: false,
          visible: false,
        },
      ],
      appointmentDTO: {
        id: 0,
        start: null,
        name: null,
        description: null,
        send_notification: true,
        accept_recording: false,
        available_youtube: true,
        recurrenceItens: [],
        studios: [],
        teachers: [],
      },
      appointment_active: true,
      isloading: false,

      jobTypeProductsSelected: null,
      jobTypeProductList: [],

      appointment_pending: false,
    };
  },
  destroyed() {
    this.$destroy();
  },
  mounted() {
    // agendamento de uma pendencia
    this.appointment_pending = this.$route.query.pid > 0;

    this.rescheduling = this.$route.params.id > 0;
    if (this.rescheduling) this.getAppoiment();
    else this.populatePending();
  },
  destroyed() {
    this.$destroy();
  },
  methods: {
    stepActive(_itemStep, i) {
      return _itemStep.index === i && this.step_current === i;
    },
    setStep(_itemStep) {
      this.step_current = _itemStep.index;
    },
    nexthourDate(_date, _itemStep) {
      this.appointmentDTO.start = _date; // agendar

      _itemStep.display = `${this.$utils.reverseDate(
        this.appointmentDTO.start
      )} às ${this.$utils.getHour(this.appointmentDTO.start).substring(0, 5)}h`;
      _itemStep.ok = true;

      this.nextActiveStep(_itemStep);
    },
    nextProject(obj, _itemStep) {

      // se alterar o projeto ou tipo, recomeça todos os passos;
      if( this.appointmentDTO.id == 0 &&
          ((this.appointmentDTO.jobTypeSelected != null && 
            this.appointmentDTO.jobTypeSelected != obj.jobTypeSelected) 
          || 
           (this.appointmentDTO.projectSelected != null &&
            this.appointmentDTO.projectSelected != obj.projectSelected))
        )
      {
        this.steps.forEach( fe => { if (fe.index < 1){fe.ok = true} else {fe.ok = false} });
      }

      this.appointmentDTO = obj;

      if (obj.jobTypeSelected) {
        _itemStep.display = `Projeto: ${obj.projectSelected.name} / ${obj.jobTypeSelected.label}`;
        _itemStep.ok = true;

        this.constrolVisibleStep();
        this.nextActiveStep(_itemStep);
      } else {
        this.$utils.toastError(
          "Regendamento",
          "Selecione o Tipo de agendamento para continuar."
        );
      }
    },
    nextEventOrLesson(obj, _itemStep) {
      this.appointmentDTO = obj;

      _itemStep.display = `${
        this.appointmentDTO.dsLesson
          ? "Aula: " + this.appointmentDTO.dsLesson
          : "Evento: " + this.appointmentDTO.dsEvent
      }`;
      _itemStep.ok = true;

      this.constrolVisibleStep();
      this.nextActiveStep(_itemStep);
    },
    nextsubeventOrders(obj, _itemStep) {
      this.appointmentDTO = obj;

      _itemStep.display = "Ordenação: OK";
      _itemStep.ok = true;
      this.constrolVisibleStep();
      this.nextActiveStep(_itemStep);
    },
    nextStudio(obj, _itemStep) {
      this.appointmentDTO = obj;
      _itemStep.ok = true;

      if (this.appointmentDTO.studios.length > 0) {
        const studios = this.appointmentDTO.studios.filter((f) => f.main);

        const studioName = studios.length > 0
          ? studios[0].name
          : this.appointmentDTO.studios[0].name;

        _itemStep.display = "Estúdio: " + studioName;
        if (this.appointmentDTO.is_home_office) {
          _itemStep.display += " / HOMEOFFICE";
        }
      }

      if (!this.appointment_pending && this.rescheduling) {
        this.constrolVisibleStep();
      }
      this.nextActiveStep(_itemStep);
    },
    nextStudioSupport(obj, _itemStep) {
      this.appointmentDTO = obj;
      _itemStep.ok = true;

      if (obj.studios.some((s) => !s.main)) {
        _itemStep.display = `Apoio: ${
          obj.studios.filter((f) => !f.main).length
        } associado(s)`;
      } else {
        _itemStep.display = `Apoio: Nenhum associado. `;
      }
      this.nextActiveStep(_itemStep);
    },
    nextRecording(obj, _itemStep) {
      this.appointmentDTO = obj;

      if (obj.appointmentSubjects && obj.appointmentSubjects.length > 0) {
        _itemStep.display = `Gravação: ${obj.appointmentSubjects.length} assunto(s) associado(s)`;
      } else {
        _itemStep.display = "Gravação: nenhum assunto associado.";
      }
      _itemStep.ok = true;

      this.constrolVisibleStep();
      this.nextActiveStep(_itemStep);
    },
    nextTransmission(obj, _itemStep) {
      if (obj.links && obj.links.length > 0) {
        _itemStep.display = `Transmissão: ${obj.links.length} link(s)`;
      } else {
        _itemStep.display = "Transmissão: nenhum adicionado.";
      }
      _itemStep.ok = true;

      this.appointmentDTO = obj;
      this.nextActiveStep(_itemStep);
    },
    nextProducts(obj, _itemStep) {
      if (
        obj.jobTypeProductsSelected &&
        obj.jobTypeProductsSelected.length > 0
      ) {
        _itemStep.display =
          "Produtos: " +
          obj.jobTypeProductsSelected.map((m) => m.label).join(", ");
      } else {
        _itemStep.display = "Produtos: nenhum adicionado.";
      }

      _itemStep.ok = true;

      this.appointmentDTO = obj;
      this.nextActiveStep(_itemStep);
    },
    nextDetails(_itemStep) {
      _itemStep.display = "Opcionais - OK";
      _itemStep.ok = true;
      this.nextActiveStep(_itemStep);
    },
    nextActiveStep(_itemCurrentStep) {
      for (var i = 0; i < this.steps.length; i++) {
        if (this.steps[i].index > _itemCurrentStep.index) {
          if (this.steps[i].visible) {
            // próximo step ativo
            this.step_current = this.steps[i].index;
            break;
          }
        }
      }
    },

    constrolVisibleStep() {
      this.constrolVisibleStudioStep();
      this.constrolVisibleStudioSupportStep();

      // Não pode ser evento todo gravado
      // aceita material gravado parcial
      if (
        !this.appointmentDTO.recording_all_events &&
        this.appointmentDTO.accept_recording
      ) {
        this.constrolVisibleRecordingStep();
      }
      this.constrolVisibleProducts();
      this.controlVisibleSubEventsOrders();
      this.constrolVisibleRecorrence();
    },
    constrolVisibleStudioStep() {
      // controlar exibição de estudio
      const _presential = this.appointmentDTO.jobTypeSelected
        ? this.appointmentDTO.jobTypeSelected.jobFormats.some(
            (s) => s.formatType.id === this.$utils.enums.presential
          )
        : true;

      const _remote = this.appointmentDTO.jobTypeSelected
        ? this.appointmentDTO.jobTypeSelected.jobFormats.some(
            (s) => s.formatType.id === this.$utils.enums.remote
          )
        : false;

      this.steps.filter((f) => f.index === 4)[0].visible = false; // step studio
      let showStudio = false;

      // 2 - aula, 1 - evento
      if (this.appointmentDTO.type === 2) {
        // validar a partir da aula
        //jobtype aceita presencial e não escolheu a opção material gravado.
        if (_presential && !this.appointmentDTO.accept_recording) {
          showStudio = true;
        }
      } else if (this.appointmentDTO.type === 1) {
        // condições para evento.

        showStudio = false;

        //caso nenhuma das opções for escolhida, deve ser exibir o estúdio
        if (
          !this.appointmentDTO.recording_all_events &&
          !this.appointmentDTO.accept_recording
        ) {
          showStudio = true;
        }

        // evento todo gravado, não deve exibir escolha de estúdio.
        if (this.appointmentDTO.recording_all_events) {
          showStudio = false;
        }

        // caso o estudio ainda não seja visivel
        if (!showStudio) {
          showStudio = this.appointmentDTO.accept_recording;
        }
      }
      this.steps.filter((f) => f.index === 4)[0].visible = showStudio;
    },
    constrolVisibleStudioSupportStep() {
      // controlar exibição de estudio de apoio
      this.steps.filter((f) => f.index === 5)[0].visible = false; // step studio de apoio
      let showStudio = false;

      // 1-evento, 2-aula
      if (this.appointmentDTO.type === 1) {
        showStudio = this.appointmentDTO.is_support_studio;
        this.steps.filter((f) => f.index === 5)[0].visible = showStudio;
      }
    },
    constrolVisibleRecordingStep() {
      this.steps.filter((f) => f.index === 6)[0].visible = false; // Step Gravações

      // apenas para evento
      // 1 - evento, 2 - aula
      if (this.appointmentDTO.type === 1) {
        let showRecording =
          this.appointmentDTO.recording_all_events ||
          this.appointmentDTO.accept_recording;

        this.steps.filter((f) => f.index === 6)[0].visible = showRecording;

        // alterar display
        this.steps.filter((f) => f.index === 6)[0].display = this.steps.filter(
          (f) => f.index === 6
        )[0].name;

        if (
          this.appointmentDTO.appointmentSubjects &&
          this.appointmentDTO.appointmentSubjects.length > 0
        ) {
          this.steps.filter(
            (f) => f.index === 6
          )[0].display = `Gravação: ${this.appointmentDTO.appointmentSubjects.length} assunto(s) associado(s)`;
        }
      }
    },
    constrolVisibleProducts() {
      this.getProducts();

      // Step products
      this.steps.filter((f) => f.index === 8)[0].visible =
        this.appointmentDTO.jobTypeProductList &&
        this.appointmentDTO.jobTypeProductList.length > 0;
    },
    constrolVisibleRecorrence() {
      this.steps.filter((f) => f.index === 10)[0].visible =
        this.appointmentDTO.recurrenceItens &&
        this.appointmentDTO.recurrenceItens.length > 0;

      if (this.steps.filter((f) => f.index === 10)[0].visible) {
        this.steps.filter(
          (f) => f.index === 10
        )[0].name = `Recorrência - ${this.appointmentDTO.recurrenceItens.length} agendamentos`;

        this.appointmentDTO.demandAudioVisual = this.demandAudioVisual;
        this.appointmentDTO.transmission_type = this.transmission_type;
      }
    },
    controlVisibleSubEventsOrders() {
      this.steps.filter((f) => f.index === 3)[0].visible =
        this.appointmentDTO.eventsSelected &&
        this.appointmentDTO.eventsSelected.subEvents &&
        this.appointmentDTO.eventsSelected.subEvents.length > 0 &&
        this.appointmentDTO.recurrenceItens.length <= 0;
    },

    nextActiveStep(_itemCurrentStep) {
      for (var i = 0; i < this.steps.length; i++) {
        if (this.steps[i].index > _itemCurrentStep.index) {
          if (this.steps[i].visible) {
            // próximo step ativo
            this.step_current = this.steps[i].index;
            break;
          }
        }
      }
    },

    getProducts() {
      if (this.appointmentDTO.jobTypeSelected) {
        this.appointmentDTO.jobTypeProductList =
          this.appointmentDTO.jobTypeSelected.jobProducts.map((m) => {
            return {
              label: m.products.name,
              value: m.products.id,
            };
          });
      }
    },
    onClickSubmit() {
      if (!this.isloading) {
        this.isloading = true;
        let req = this.getPayloadAppointment();
        const payload = { data: { ...req } };

        // promisse
        const _createOrUpdate =
          req.id <= 0
            ? _appointmentService.create(payload)
            : _appointmentService.update(payload);

        // exec
        _createOrUpdate
          .then((_res) => {
            this.$utils.toast("Notificação", "Criado com sucesso.");

            // redirecionar abrindo aba.
            this.$router.push({
              name: "pedagogic-calendar",
              query: { q: _res.content.id },
            });
          })
          .catch((error) => this.$utils.toastError("Notificação", error))
          .finally(() => (this.isloading = false));
      }
    },
    getPayloadAppointment() {
      this.appointmentDTO.demandAudioVisual = this.demandAudioVisual;
      this.appointmentDTO.transmission_type = this.transmission_type;
      return this.$utils.getPayloadAppointment(this.appointmentDTO, this.steps);
    },

    getAppoiment() {
      this.isloading = true;
      _appointmentService
        .findbyid(this.$route.params.id)
        .then((res) => {
          // agendamento cancelado
          this.appointment_active = res.content.appointment.active;
          if (!res.content.appointment.active) {
            this.$utils.toastError(
              "Agendamento",
              "Esse agendamento se encontra cancelado."
            );
            return;
          }
          this.populateAppointment(res);

          // remover campos preenchidos do agendamento pai, quando for pendencia
          this.populatePending();
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.isloading = false));
    },
    populateAppointment(res) {
      if (res.content) {
        // tem gravação pendente
        this.appointmentDTO.recording_pending = res.content.recording_pending;

        // tem studio de apoio
        this.appointmentDTO.is_support_studio = res.content.is_support_studio;

        const apt = res.content.appointment;
        this.appointmentDTO.send_notification = apt.send_notification;
        this.appointmentDTO.accept_recording = apt.accept_recording;
        this.appointmentDTO.is_home_office = apt.is_home_office;
        this.appointmentDTO.description = apt.description;
        this.appointmentDTO.name = apt.name;
        this.appointmentDTO.type = apt.type;
        this.appointmentDTO.id = apt.id;

        // step 0 = data e hora
        this.appointmentDTO.start = apt.start;
        this.nexthourDate(
          apt.start,
          this.steps.filter((f) => f.index === 0)[0]
        );

        // step 1 - Projetos
        const _project = apt.lesson
          ? apt.lesson.course.project
          : apt.event.project;

        this.appointmentDTO.projectSelected = {
          id: _project.id,
          name: _project.name,
          company_id: _project.company.id,
          company: _project.company.name,
          companycolor: _project.companycolor,
        };

        this.appointmentDTO.jobTypeSelected = null;
        if (apt.jobType) {
          this.appointmentDTO.jobTypeSelected = {
            label: apt.jobType.name,
            value: apt.jobType.id,
            ...apt.jobType,
          };
        }
        this.nextProject(
          this.appointmentDTO,
          this.steps.filter((f) => f.index === 1)[0]
        );

        // step 2 - Evento / aula
        this.appointmentDTO.appointmentSubjects = apt.appointmentSubjects; // gravações
        this.appointmentDTO.duration = apt.lesson
          ? apt.lesson.duration
          : apt.event.duration;

        if (apt.lesson) {
          this.appointmentDTO.dsLesson = apt.lesson.name;
          this.appointmentDTO.lesson_id = apt.lesson.id;
          this.appointmentDTO.lessonsSelected = {
            duration: apt.lesson.duration,
            label: apt.lesson.name,
            value: apt.lesson.id,
          };
          if (apt.lesson.course)
            this.appointmentDTO.teachers = apt.lesson.course.teachers; // professores
        } else {
          this.appointmentDTO.dsEvent = apt.event.name;
          this.appointmentDTO.event_id = apt.event.id;
          this.appointmentDTO.eventsSelected = {
            subEvents: apt.event.subEvents,
            duration: apt.event.duration,
            label: apt.event.name,
            value: apt.event.id,
          };
          if (apt.event.subEvents)
            apt.event.subEvents.forEach((fe) => {
              fe.subEventsTeachers.forEach((fe2) =>
                this.appointmentDTO.teachers.push({
                  teacher_id: fe2.teacher_id,
                })
              );
            }); // professores

          // display events
          this.appointmentDTO.recording_all_events = apt.recording_all_events;
          if (this.appointmentDTO.recording_all_events) {
            this.appointmentDTO.dsEvent += " / Todo Gravado";
          }

          if (apt.accept_recording && this.appointmentDTO.is_support_studio) {
            this.appointmentDTO.dsEvent += " / Com Gravação e Estúdio de apoio";
          } else {
            if (apt.accept_recording) {
              this.appointmentDTO.dsEvent += " / Com Gravação";
            }

            if (this.appointmentDTO.is_support_studio) {
              this.appointmentDTO.dsEvent += " / Com estúdio de apoio";
            }
          }
        }
        this.nextEventOrLesson(
          this.appointmentDTO,
          this.steps.filter((f) => f.index === 2)[0]
        );

        //step 3 - ordenação subevents
        if (apt.event) {
          this.nextsubeventOrders(
            this.appointmentDTO,
            this.steps.filter((f) => f.index === 3)[0]
          );
        }

        // step 4 - estúdio
        this.appointmentDTO.studios = [];
        if (apt.studios && apt.studios.length > 0) {
          this.appointmentDTO.studios = apt.studios.map((m) => {
            return {
              subevent_id: m.subevent_id,
              studio_id: m.studio.id,
              name: m.studio.name,
              main: m.main,
              id: m.id,
            };
          });
        }
        this.nextStudio(
          this.appointmentDTO,
          this.steps.filter((f) => f.index === 4)[0]
        );

        // step 5 - estúdio de apoio
        if (this.appointmentDTO.is_support_studio) {
          this.nextStudioSupport(
            this.appointmentDTO,
            this.steps.filter((f) => f.index === 5)[0]
          );
        }

        // step 6 - Gravações
        if (
          this.appointmentDTO.appointmentSubjects &&
          this.appointmentDTO.appointmentSubjects.length > 0
        ) {
          this.nextRecording(
            this.appointmentDTO,
            this.steps.filter((f) => f.index === 6)[0]
          );
        }

        // step 7 - Transmissão
        this.appointmentDTO.links = [];
        if (apt.appointmentLinks.length > 0) {
          this.appointmentDTO.links = apt.appointmentLinks.map((m) => {
            return {
              url: m.url,
              url_title: m.url_title,
              id: m.id,
              jobTypeTransmissionSelected: m.transmissionType
                ? {
                    label: m.transmissionType.name,
                    value: m.transmissionType.id,
                  }
                : null,
              companieSelected: {
                enable_youtube_api: m.company.enable_youtube_api,
                label: m.company.name,
                value: m.company.id,
              },
              statusVisibilitySelected: {
                label: m.ds_visibility,
                value: m.visibility,
              },
            };
          });
        }
        this.nextTransmission(
          this.appointmentDTO,
          this.steps.filter((f) => f.index === 7)[0]
        );

        // agendamento sem jobtype deixar aberto para seleção, (agendamento antigos.)
        if (!apt.jobType) {
          this.setStep(this.steps.filter((f) => f.index === 1)[0]);
        }

        // step 8 - Produtos
        if (apt.appointmentProducts && apt.appointmentProducts.length > 0) {
          this.appointmentDTO.jobTypeProductsSelected =
            apt.appointmentProducts.map((m) => {
              return {
                label: m.product.name,
                value: m.product.id,
              };
            });
        }
        this.nextProducts(
          this.appointmentDTO,
          this.steps.filter((f) => f.index === 8)[0]
        );

        // step 9 - OK
        this.steps.filter((f) => f.index === 9)[0].display = "Agendamento";
        this.steps.filter((f) => f.index === 9)[0].ok = true;
      }
    },
    async populatePending() {
      if (this.appointment_pending) {
        this.appointmentDTO.recording_media_id = this.$route.query.pid;
        this.appointmentDTO.appointment_pending = true;
        this.appointmentDTO.id = 0;

        // PENDING
        const _resApt = await _appointmentRequests.find(
          this.appointmentDTO.recording_media_id
        );

        if (_resApt.content) {
          if (_resApt.content.recommended_date) {
            this.appointmentDTO.start = _resApt.content.recommended_date;
          }

          if (_resApt.content.recommended_duration) {
            this.appointmentDTO.duration_pending =
              _resApt.content.recommended_duration;
          }
        }

        //se já tiver preenchido não preencher novamente
        if (!this.appointmentDTO.duration_pending) {
          if (this.appointmentDTO.lessonsSelected) {
            this.appointmentDTO.duration_pending =
              this.appointmentDTO.lessonsSelected.duration;
          } else {
            this.appointmentDTO.duration_pending = "01:00";
          }
        }

        if (this.rescheduling) {
          // ESCONDER STEPS
          this.steps.forEach((_tp) => {
            _tp.visible = false;
          });

          this.steps[0].visible = true; // data e hora
          this.steps[9].visible = true; // Agendamento nome e descrição

          // Estúdio
          this.steps[4].visible = true;
          this.steps[4].display = "Estúdios";
          this.appointmentDTO.is_home_office = false;
          this.appointmentDTO.studios = [];

          // DATA E HORA
          //this.appointmentDTO.start = null;
          this.steps[0].display = "Escolha data e hora";
          this.steps[0].ok = false;
          this.setStep({ index: 0 });
        }

        // JOBTYPE - SET GRAVAÇÕES OFF
        _jobTypeService
          .showJobTypeAll()
          .then((res) => {
            const recording = res.content.filter(
              (f) => f.name.toLowerCase() == "gravações off"
            );

            const _job = recording.length > 0 ? recording[0] : null;
            if (_job) {
              this.appointmentDTO.jobTypeSelected = {
                label: _job.name,
                value: _job.id,
                ..._job,
              };

              // PROJETO / jobtype
              this.steps[1].display = `Projeto: ${
                this.appointmentDTO.projectSelected
                  ? this.appointmentDTO.projectSelected.name
                  : ""
              } / ${this.appointmentDTO.jobTypeSelected.label}`;
              this.steps[1].ok = false;

              // por ser pendencia não podemos ser gravado inicialmente.
              this.appointmentDTO.recording_all_events = false;
              this.steps[2].display = this.steps[2].display.replace(
                "/ Todo Gravado",
                ""
              );
            }
          })
          .catch((error) => this.$utils.toastError("Notificação", error))
          .finally(() => (this.isloading = false));
      }
    },
  },
};
</script>