<template>
  <b-overlay :variant="skin" :show="isloading" spinner-variant="primary" spinner-type="grow">
    <b-row class="pt-1">
      <b-col cols="12">
        <draggable
          :list="subevents"
          tag="ul"
          group="people"
          class="list-group list-group-flush cursor-move"
        >
          <b-list-group-item
            v-for="(item, index) in subevents"
            :key="index"
            tag="li"
            class="border mb-75"
          >
            <div class="d-flex">
              <small>#{{ index + 1 }} - </small>
              <div class="ml-25">
                <b-card-text class="mb-0 font-weight-bold">
                  {{ item.name }}
                </b-card-text>
              </div>
            </div>
          </b-list-group-item>
        </draggable>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col cols="12">
        <b-button variant="info" @click="onClickNext">
          <span class="mr-25 align-middle">Próximo</span>
        </b-button>
      </b-col>
    </b-row>
  </b-overlay>
</template>
<script>
import { BListGroupItem } from "bootstrap-vue";
import draggable from "vuedraggable";
import useAppConfig from "@core/app-config/useAppConfig";

export default {
  setup() {
    const { skin } = useAppConfig();
    return {
      skin,
    };
  },
  components: {
    BListGroupItem,
    draggable,
  },
  props: {
    appointment: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      isloading: false,
      subevents: [],
    };
  },
  created() {
    this.subevents = this.appointment.eventsSelected.subEvents;
  },
  methods: {
    onClickNext() {
      this.appointment.subEventsOrder = [];
      for (let isub = 0; isub < this.subevents.length; isub++) {
        this.appointment.subEventsOrder.push({
          subevent_id: this.subevents[isub].id,
          appointment_id: this.appointment.id,
          order: isub,
        });
      }
      this.$emit("next", this.appointment);
    },
  },
};
</script>