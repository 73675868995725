<template>
  <div>
    <b-row class="mt-1">
      <b-col cols="8">
        <b-form-group label="Informe um nome">
          <b-form-input
            v-model="appointment.name"
            placeholder="Informe um nome (opcional)"
          />
        </b-form-group>
      </b-col>

      <b-col cols="8">
        <b-form-group label="Descrição">
          <quill-editor
            :options="editorOption"
            v-model="appointment.description"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12" class="mb-1">
        <b-card-text class="mb-0"> Notificar participantes ? </b-card-text>
        <b-form-checkbox
          v-model="appointment.send_notification"
          class="custom-control-success"
          name="check-button"
          switch
        >
          <span class="switch-icon-left">
            <feather-icon icon="MailIcon" />
          </span>
          <span class="switch-icon-right">
            <feather-icon icon="XIcon" />
          </span>
        </b-form-checkbox>
      </b-col>
    </b-row>

    <b-row
      class="mt-3"
      v-if="
        appointment.recurrenceItens && appointment.recurrenceItens.length > 0
      "
    >
      <b-col cols="12">
        <b-button variant="info" @click="onClickNext">
          <span class="mr-25 align-middle">Próximo</span>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  props: {
    appointment: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ font: [] }],
            [{ color: [] }],
          ],
        },
        placeholder: "Informe uma breve descrição (opcional)"
      },
    };
  },
  methods: {
    onClickNext() {
      this.$emit("next", this.appointment);
    },
  },
};
</script>