import HttpService from './HttpService'

const _http = new HttpService()
export default {
  show: (mes = 0) => {
    return _http.get(`/api/appointment?mes=${mes}`)
  },
  showRange: (params) => {
    let url = `/api/appointment/calendars?start=${params.start}&end=${params.end}&active=${params.active}&is_canceled_out_of_time=${params.is_canceled_out_of_time}`
    if (params?.teacher_id) {
      url = url + `&teacher_id=${params?.teacher_id}`
    }
    if (params?.company_name_slug) {
      url = url + `&company_name_slug=${params?.company_name_slug}`
    }
    return _http.get(url)
  },
  find: (id) => {
    return _http.get(`/api/appointment/${id}`)
  },
  findbyid: (id) => {
    return _http.get(`/api/appointment/findbyid/${id}`)
  },
  // Obsoleto
  create: (payload) => {
    return _http.post('/api/appointment', payload)
  },
  // Obsoleto
  createRecurrence: (payload) => {
    return _http.post('/api/appointment/recurrence', payload)
  },
  createImmediateSchedule: (payload) => {
    return _http.post('/api/appointment/immediate-scheduling', payload)
  },
  createCloneEvent: (payload) => {
    return _http.post('/api/appointment/clone-event', payload)
  },
  update: (payload) => {
    return _http.put('/api/appointment', payload)
  },
  updateStudio: (payload) => {
    return _http.put('/api/appointment/studios', payload)
  },
  changeStudio: (payload) => {
    return _http.put('/api/appointment/change-studios', payload)
  },
  updateAvailYoutube: (payload) => {
    return _http.put('/api/appointment/avail-youtube', payload)
  },
  updateAvailYoutubeVisibility: (payload) => {
    return _http.put('/api/appointment/avail-youtube-visibility', payload)
  },
  delete: (id) => {
    return _http.delete(`/api/appointment/${id}`)
  },
  showProjects: (search, start) => {
    return _http.get(`/api/appointment/projects?search=${search}&data.start=${start}&size=30`)
  },
  showProjectsCheck: (projectid, start, duration) => {
    return _http.get(`/api/appointment/projects?data.start=${start}&data.id=${projectid}&data.duration=${duration}`)
  },
  showStudios: (search, start, size = 12) => {
    return _http.get(`/api/appointment/studios?search=${search}&size=${size}&data.start=${start}`)
  },
  showAllStudios: (search, start, duration, homeoffice = false, appointmentId = 0, event_id = 0, presential = null, jobtype_id = null, lesson_id = 0, ignore_preparation = false, nocache = false) => {
    return _http.get(`/api/appointment/studios?search=${search}&size=${20}&data.start=${start}&data.duration=${duration}&data.homeoffice=${homeoffice}&data.appointmentId=${0}&data.event_id=${event_id}&data.presential=${presential}&data.jobtype_id=${jobtype_id}&data.lesson_id=${lesson_id}&data.ignore_preparation=${ignore_preparation}&data.nocache=${nocache}`)
  },
  showAllStudiosApoio: (search, start, event_id, subevent_id, appointmentId, jobtypeid) => {
    return _http.get(`/api/appointment/studios-apoio?search=${search}&size=${20}&data.start=${start}&data.event_id=${event_id}&data.subevent_id=${subevent_id}&data.jobtype_id=${jobtypeid}&data.appointmentId=${appointmentId}`)
  },
  showLessons: (id) => {
    return _http.get(`/api/appointment/${id}/lessons`)
  },
  createOperator: (payload) => {
    return _http.post(`/api/appointment/operator`, payload)
  },
  updateWithOutTime: (payload) => {
    return _http.put('/api/appointment/cancel-out-time', payload)
  },

  /* CHATS */
  createChat: (payload) => {
    return _http.post(`/api/appointment/chat`, payload)
  },

  getChat: (appointment_id) => {
    return _http.get(`/api/appointment/short/${appointment_id}/chat`)
  },

  lockOrUnlockUpload: (appointment_id) => {
    return _http.post(`/api/appointment/ignore-upload/${appointment_id}`)
  },

  showScaleOperators: (page, size, params = null) => {
    let _parans = `?size=${size}&page=${page - 1}`;
    if (params) {
      if (params.date)
        _parans += `&data.date=${params.date}`;
      if (params.company_id)
        _parans += `&data.company_id=${params.company_id}`;
      if (params.group_studio_id)
        _parans += `&data.group_studio_id=${params.group_studio_id}`;
      if (params.operator_id)
        _parans += `&data.operator_id=${params.operator_id}`;
      if (params.moderator_id)
        _parans += `&data.moderator_id=${params.moderator_id}`;
      if (params.studio_id)
        _parans += `&data.studio_id=${params.studio_id}`;
      if (params.place_id)
        _parans += `&data.place_id=${params.place_id}`;
      if (params.orderByKey)
        _parans += `&order_by=${params.orderByKey}`
      if (params.ascOrDes)
        _parans += '&asc'
    }
    return _http.get(`/api/appointment/scale-operators${_parans}`)
  },

  upSertScaleOperators: (payload) => {
    return _http.put('/api/appointment/scale-operators/upsert', payload)
  },

  showScaleOperatorsDashboard: (params = null) => {
    let _parans = `?page=0&asc`;
    if (_parans) {

      if (params && params.date)
        _parans += `&data.date=${params.date}`;
    }

    if (params && params.operator_id)
      _parans += `&data.operator_id=${params.operator_id}`;

    return _http.get(`/api/appointment/scale-operators/dashboard${_parans}`)
  },
  showScaleOperatorsDashboardLate: (params = null) => {
    let _parans = `?page=0&asc`;
    if (_parans) {

      if (params && params.date)
        _parans += `&data.date=${params.date}`;
    }

    if (params && params.operator_id)
      _parans += `&data.operator_id=${params.operator_id}`;

    return _http.get(`/api/appointment/scale-operators/dashboard-late${_parans}`)
  },
  showAllRecording: (event_id, course_id) => {
    return _http.get(`/api/appointment/recording?&data.event_id=${event_id}&data.course_id=${course_id}`)
  },
  showSubjects: (company_id) => {
    return _http.get(`/api/appointment/recording/${company_id}/subjects`)
  },
  showSubjectsByTeachers: (params = null) => {
    let _parans = `?`;
    if (params.company_id)
      _parans += `data.company_id=${params.company_id}`;
    if (params)
      params.teachers.forEach(e => {
        if (e.teacher_id)
          _parans += `&data.teacher_id=${e.teacher_id}`;
      });
    return _http.get(`/api/appointment/recording-by-teachers${_parans}`)
  },
  updateNote: (payload) => {
    return _http.post(`/api/appointment/update-note`, payload)
  },
  getHistoric: (appointment_id) => {
    return _http.get(`/api/appointment/short/${appointment_id}/historic`)
  },
  getStatusVisibility: () => {
    return _http.get(`/api/appointment/status-visibility`)
  },
  find_short: (appointment_id, teacher_id) => {
    let url = `/api/appointment/short/${appointment_id}`
    if (teacher_id) {
      url = url + `?teacher_id=${teacher_id}`
    }
    return _http.get(url)
  },
  find_short_studios: (appointment_id) => {
    return _http.get(`/api/appointment/short/${appointment_id}/studios`)
  },
  find_short_links: (appointment_id) => {
    return _http.get(`/api/appointment/short/${appointment_id}/links`)
  },
  find_links: (appointment_id) => {
    return _http.get(`/api/appointment/${appointment_id}/links`)
  },
  sync_link_calendar: (appointment_id) => {
    return _http.get(`/api/appointment/${appointment_id}/sync-link-calendar`)
  },
  cancelReason: (payload) => {
    return _http.post(`/api/appointment/cancel-reason`, payload)
  },
  sync_title_calendar: (payload) => {
    return _http.put(`/api/appointment/sync-title-calendar`, payload)
  },
  getClassifications: (appointment_id) => {
    return _http.get(`/api/appointment/short/${appointment_id}/classifications`)
  },
  getAppointmentsByStudio: (studio_id) => {
    return _http.get(`/api/appointment/appointments-by-studio/${studio_id}`)
  },
}