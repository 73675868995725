<template>
  <main class="mt-2">
    <b-alert
      dismissible
      fade
      class="mb-3 mt-1"
      variant="warning"
      :show="loadingRecorrence"
      @dismissed="loadingRecorrence = false"
    >
      <div class="alert-body mr-1">
        <span>Estamos processando os agendamentos em fila, AGUARDE!!!</span>
        <br />
        <small
          >Você pode acompanhar o progresso ao lado de cada item na fila.</small
        >
      </div>
    </b-alert>
    <b-row v-for="(item, i) in this.appointment.recurrenceItens" :key="i">
      <b-col cols="12" md="5">
        <b-form-group label="Data Prevista">
          <b-form-datepicker v-model="item.dataprevius" class="mb-1" />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="2">
        <b-form-group label="Hora Prevista">
          <b-form-timepicker
            v-model="item.hour"
            minutes-step="15"
            locale="pt-BR"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="4" v-if="!appointment.is_home_office">
        <b-form-group label="Estúdio">
          <v-select
            v-model="item.studiosSelected"
            :options="studios"
            searchable
            @search="
              (term) => {
                fetchStudios(term, item);
              }
            "
            :loading="item.isloading"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="4" v-else>
        <b-form-group label="Estúdio">
          homeoffice <b-spinner type="grow" v-if="item.isloading"
        /></b-form-group>
      </b-col>
      <b-col cols="12" md="1">
        <div v-if="item.status" style="margin-top: 32px">
          <div v-if="item.ok">
            <b-avatar
              :id="`status-app-recorrence-${i}`"
              variant="success"
              size="25"
            >
              <feather-icon icon="CheckIcon" size="16" />
            </b-avatar>
          </div>
          <div v-else>
            <feather-icon
              :id="`status-app-recorrence-${i}`"
              icon="BellIcon"
              size="21"
              class="text-danger"
              badge="1"
              badge-classes="badge-danger"
            />
            <b-tooltip
              triggers="hover"
              :target="`status-app-recorrence-${i}`"
              :title="`${item.description}`"
              :delay="{ show: 100, hide: 50 }"
              v-if="item.description"
            />
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" v-if="!loadingRecorrence">
        <b-button
          :variant="reprocess ? 'warning' : 'info'"
          @click="onclickProcess"
        >
          <span class="mr-25 align-middle"
            >{{ reprocess ? "Reprocessar" : "Processar" }}
          </span>
        </b-button>
      </b-col>
      <b-col cols="12" v-else>
        <b-alert
          fade
          class="mb-3 mt-1"
          variant="warning"
          :show="loadingRecorrence"
        >
          <div class="alert-body mr-1">
            <span>Estamos processandos os agendamento em fila, AGUARDE!!!</span>
            <br />
            <small
              >Você pode acompanhar o progresso ao lado de cada item na
              fila.</small
            >
          </div>
        </b-alert>
      </b-col>
    </b-row>
  </main>
</template>

<script>
import _appointmentService from "@/services/appointment-service";
import { BFormDatepicker, BAlert } from "bootstrap-vue";
export default {
  components: { BFormDatepicker, BAlert },

  props: {
    appointment: {
      type: Object,
      default: () => null,
    },
    steps: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      studios: [],
      eventsSelected: null,
      presential: false,
      loadingRecorrence: false,
      reprocess: false,
    };
  },
  created() {
    // estudio main
    let _studio =
      this.appointment.studios && this.appointment.studios.length > 0
        ? {
            label: this.appointment.studios[0].name,
            value: this.appointment.studios[0].studio_id,
          }
        : null;

    // setar estudio main
    this.appointment.recurrenceItens.forEach((_r) => {
      _r.parent_id = _r.parent_id > 0 ? _r.parent_id : 0;
      _r.studiosSelected = _r.studiosSelected ? _r.studiosSelected : _studio;
      _r.isloading = false;
    });

    this.presential = this.appointment.jobTypeSelected.jobFormats.some(
      (s) => s.formatType.id === this.$utils.enums.presential
    );

    this.eventsSelected = this.appointment.eventsSelected;
  },
  methods: {
    fetchStudios(_term, item) {
      if (_term && _term.length >= 3) {
        setTimeout(() => {
          if (!item.isloading) {
            item.isloading = true;
            _appointmentService
              .showAllStudios(
                _term,
                this.appointment.start,
                this.appointment.duration,
                this.appointment.is_home_office,
                this.appointment.id,
                this.appointment.event_id,
                this.presential,
                this.appointment.jobTypeSelected.id,
                this.appointment.lesson_id
              )
              .then((res) => {
                if (res.content) {
                  this.studios = res.content.map((m) => {
                    return {
                      label: m.name,
                      value: m.id,
                    };
                  });
                }
              })
              .catch((error) => this.$utils.toastError("Notificação", error))
              .finally(() => (item.isloading = false));
          }
        }, 500);
      }
    },
    async onclickProcess() {
      this.loadingRecorrence = true;

      for (
        let index = 0;
        index < this.appointment.recurrenceItens.length;
        index++
      ) {
        this.appointment.recurrenceItens[index].description = null;

        if (
          !this.appointment.recurrenceItens[index].ok &&
          !this.appointment.recurrenceItens[index].isloading
        ) {
          this.appointment.recurrenceItens[index].isloading = true;

          if (index > 0) {
            this.appointment.recurrenceItens[index].parent_id =
              this.appointment.eventsSelected.value;
            await this.cloneEvent(this.appointment.recurrenceItens[index]);
          }

          if (!this.appointment.recurrenceItens[index].description) {
            await this.createAppointment(
              this.appointment.recurrenceItens[index]
            );
          }
        }

        this.reprocess = this.appointment.recurrenceItens.some(
          (s) => s.ok !== true
        );

        if (!this.reprocess) {
          this.$router.push({ name: "pedagogic-calendar" });
        }
      }
      this.loadingRecorrence = false;
    },

    async cloneEvent(recurrence) {
      const payload = {
        data: {
          id: recurrence.event.id,
          parent_id: recurrence.parent_id > 0 ? recurrence.parent_id : null,
        },
      };

      if (recurrence.id > 0) {
        this.eventsSelected = {
          value: recurrence.id,
          label: "event..",
        };
      } else {
        await _appointmentService
          .createCloneEvent(payload)
          .then((res) => {
            // novo appointment com novo evento
            recurrence.id = res.content.id;
            recurrence.description = null;
            this.eventsSelected = {
              value: res.content.id,
              label: res.content.name,
              subevents: res.content.subEvents,
            };
          })
          .catch((error) => {
            recurrence.isloading = false;
            recurrence.description = error;
            recurrence.status = true;
            recurrence.ok = false;
          });
      }
    },

    async createAppointment(recurrence) {
      if (!this.appointment.is_home_office) {
        if (!recurrence.studiosSelected) {
          recurrence.description = "Selecione o estúdio";
          recurrence.status = true;
          return;
        }
      }

      let req = this.getPayloadAppointment();
      req.start = `${recurrence.dataprevius}T${recurrence.hour}:00`;

      // vincular ao novo evento clonado
      req.event_id = this.eventsSelected.value;

      // order subevents
      if (
        this.eventsSelected.subevents &&
        this.eventsSelected.subevents.length > 0
      ) {
        req.subEventsOrder = [];
        for (
          let index = 0;
          index < this.eventsSelected.subevents.length;
          index++
        ) {
          req.subEventsOrder.push({
            subevent_id: this.eventsSelected.subevents[index].id,
            order: index,
          });
        }
      }

      if (recurrence.studiosSelected) {
        // manter estudios de apoio
        req.studios = req.studios.filter((f) => !f.main);

        // add estudio main
        req.studios.push({
          studio_id: recurrence.studiosSelected.value,
          main: true,
        });
      }

      const payload = { data: { ...req } };

      recurrence.isloading = true;
      recurrence.status = false;

      await _appointmentService
        .createRecurrence(payload)
        .then((res) => {
          recurrence.status = true;
          recurrence.ok = true;
        })
        .catch((error) => {
          recurrence.description = error;
          recurrence.status = true;
          recurrence.ok = false;
        })
        .finally(() => (recurrence.isloading = false));
    },
    getPayloadAppointment() {
      return this.$utils.getPayloadAppointment(this.appointment, this.steps);
    },
  },
};
</script>