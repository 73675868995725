<template>
  <div>
    <b-button
      variant="gradient-info"
      class="btn-icon mt-25"
      size="sm"
      @click="newSupportRecording()"
    >
      <feather-icon icon="PlusIcon" />
    </b-button>
    <hr />

    <b-row
      v-for="(item, iitem) in studioRecording"
      :id="'blb-' + item.id"
      :key="iitem"
    >
      <!-- sub events -->
      <b-col cols="12" md="4">
        <b-form-group label="Sub-Evento">
          <v-select
            v-model="item.subeventsSelected"
            :options="subevents"
            autocomplete="off"
            :clearable="false"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="4" v-if="!item.pending">
        <b-form-group label="Gravações">
          <v-select
            v-model="item.gravacoesAssuntoSelected"
            :options="recordingList"
            :disabled="!item.subeventsSelected"
          />
        </b-form-group>
      </b-col>

      <b-col style="margin-top: 20px" cols="12" md="auto">
        <div class="py-1 d-flex">
          <b-form-checkbox
            class="custom-control-warning"
            name="check-button"
            v-model="item.pending"
            disabled="disabled"
            switch
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
          <span>Material Pendente ?</span>
        </div>
      </b-col>

      <b-col cols="12" md="1">
        <b-form-group label="-">
          <b-button
            variant="outline-danger"
            @click="removeStudioRecording(item)"
            size="sm"
          >
            <feather-icon size="16" icon="Trash2Icon" />
          </b-button>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col cols="12">
        <b-button variant="info" @click="onClickNext">
          <span class="mr-25 align-middle">Próximo</span>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import _appointmentService from "@/services/appointment-service";
export default {
  props: {
    appointment: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      isloading: false,
      recordingList: [],
      studioRecording: [],
      subeventsSelecteds: [],
      subevents: [],
    };
  },
  created() {
    if (this.appointment.eventsSelected.subEvents) {
      this.subevents = this.appointment.eventsSelected.subEvents.map((m) => {
        // verifica se já está sendo usado
        if (!this.appointment.studios.some((s) => s.subevent_id === m.id)) {
          // não foi selecionado na sessão studio de apoio
          return {
            duration: m.duration,
            label: m.name,
            value: m.id,
          };
        }
      });
      // validar objetos nulos
      if (this.subevents && this.subevents.length > 0) {
        this.subevents = this.subevents.filter((f) => f);
      }
    }
  },
  mounted() {
    this.getSubjects();
  },
  methods: {
    getSubjects() {
      this.isloading = true;
      let params = {
        company_id: this.appointment.projectSelected.company_id,
        teachers: [],
      };

      let subEvents = [];
      subEvents = this.appointment.eventsSelected.subEvents;
      if (subEvents)
        subEvents.forEach((fe) => {
          fe.subEventsTeachers.forEach((fe2) =>
            params.teachers.push({ teacher_id: fe2.teacher_id })
          );
        });

      _appointmentService
        .showSubjectsByTeachers(params)
        .then((res) => {
          this.recordingList = res.content.map((m) => {
            return {
              label: m.subject,
              value: m.id,
            };
          });

          // load
          this.loadRecording();
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.isloading = false));
    },
    loadRecording() {
      // montar display com as gravações
      if (this.appointment.appointmentSubjects) {
        this.studioRecording = this.appointment.appointmentSubjects.map((m) => {
          const subevent = this.subevents.filter(
            (f) => f.value === m.subevent_id
          )[0];
          const subject = this.recordingList.filter(
            (f) => f.value === m.media_file_subject_id
          )[0];
          return {
            subeventsSelected: { ...subevent },
            gravacoesAssuntoSelected: m.media_file_subject_id
              ? { ...subject }
              : null,
            pending: m.recording_pending,
            id: m.id,
          };
        });
      }
    },
    newSupportRecording() {
      this.studioRecording.forEach((sr) => {
        if (sr.subeventsSelected) {
          this.subeventsSelecteds.push(sr.subeventsSelected);
        }
      });

      // exibir apenas subeventos não escolhidos
      let _aux = [];
      this.subevents.map((m) => {
        if (!this.subeventsSelecteds.some((s) => s.value === m.value)) {
          _aux.push(m);
        }
      });
      if (_aux.length > 0) {
        this.subevents = _aux;

        // FIM Studio
        this.studioRecording.push({
          pending: true,
          gravacoesAssuntoSelected: null,
          subeventsSelected: null,
          id: this.$utils.getGuidId(),
        });
      } else {
        this.$utils.toastError("OPS!!!", "Esse e o último sub-evento");
      }
    },
    removeStudioRecording(_item) {
      // repopular lista
      this.subeventsSelecteds = [];
      this.studioRecording.forEach((sr) => {
        let _sub = sr.subeventsSelected;
        if (_sub && !this.subevents.some((s) => s.value === _sub.value)) {
          this.subevents.push({ ..._sub });
        }
      });

      // remover item
      this.studioRecording = this.studioRecording.filter(
        (f) => f.id !== _item.id
      );

      // exibir apenas subeventos não escolhidos
      let _aux = [];
      this.subevents.map((m) => {
        if (!this.subeventsSelecteds.some((s) => s.value === m.value)) {
          _aux.push(m);
        }
      });
      this.subevents = _aux;
    },

    onClickNext() {
      this.appointment.appointmentSubjects = [];

      if (this.studioRecording && this.studioRecording.length > 0) {
        // contém pelo menos um assunto pendente
        this.appointment.recording_pending = this.studioRecording.some(
          (s) => s.pending
        );

        // montar gravações
        this.appointment.appointmentSubjects = this.studioRecording.map((m) => {
          return {
            media_file_subject_id:
              !m.pending && m.gravacoesAssuntoSelected // so tem gravação se assunto não for pendente
                ? m.gravacoesAssuntoSelected.value
                : null,
            subevent_id: m.subeventsSelected.value,
            recording_pending: m.pending,
          };
        });
      }
      this.$emit("next", this.appointment);
    },
  },
};
</script>