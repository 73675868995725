<template>
  <!-- produtos -->
  <b-row class="mt-1">
    <b-col cols="8">
      <div class="demo-inline-spacing mb-3">
        <b-form-checkbox
          v-for="(prod, iprod) in jobTypeProductList"
          class="custom-control-success"
          :checked="prod.selected"
          v-model="prod.selected"
          name="check-button"
          :key="iprod"
          switch
        >
          {{ prod.label }}
          <span class="switch-icon-left">
            <feather-icon icon="CheckIcon" />
          </span>
        </b-form-checkbox>
      </div>
    </b-col>
    <b-col cols="12">
      <b-button variant="info" @click="onClickNext">
        <span class="mr-25 align-middle">Próximo</span>
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    appointment: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      jobTypeProductList: [],
      jobTypeProductsSelected: null,
    };
  },
  created() {
    this.jobTypeProductList = this.appointment.jobTypeProductList;

    // ja selecionados
    if (this.appointment.jobTypeProductsSelected) {
      // verificar dentro da lista total de produtos qual está vinculado.
      this.jobTypeProductList.forEach((prd) => {
        prd.selected = this.appointment.jobTypeProductsSelected.some(
          (s) => s.value == prd.value
        );
      });
    }
  },
  methods: {
    onClickNext() {
      const selecteds = this.jobTypeProductList
        .filter((f) => f.selected)
        .map((m) => {
          return {
            ...m,
          };
        });

      this.appointment.jobTypeProductsSelected = selecteds;
      this.$emit("next", this.appointment);
    },
  },
};
</script>