<template>
  <div>
    <div class="d-flex mt-1">
      <!-- Transmissões -->
      <b-button size="sm" variant="primary" class="mb-1" @click="addLink()">
        <feather-icon icon="PlusIcon" /> Link
      </b-button>
    </div>
    <hr />

    <b-row v-for="(ll, index) in links" :key="index" class="mb-1">
      <b-col cols="2">
        <v-select
          v-model="ll.jobTypeTransmissionSelected"
          :options="jobTypeTransmissionList"
          autocomplete="off"
          :clearable="false"
          placeholder="Tipo de Transmissão"
        />
      </b-col>
      <b-col cols="2">
        <v-select
          v-if="companies.length > 0"
          v-model="ll.companieSelected"
          :options="index === 0 ? ll.companies : companies"
          autocomplete="off"
          :clearable="false"
          placeholder="Empresas"
        />
      </b-col>
      <b-col cols="3">
        <b-form-input
          :placeholder="
            ll.jobTypeTransmissionSelected &&
            ll.jobTypeTransmissionSelected.label === 'Youtube' &&
            ll.companieSelected &&
            ll.companieSelected.enable_youtube_api
              ? 'Geração link automática'
              : 'Informe a URL'
          "
          autocomplete="off"
          v-model="ll.url"
          :disabled="
            ll.jobTypeTransmissionSelected &&
            ll.jobTypeTransmissionSelected.label === 'Youtube' &&
            ll.companieSelected &&
            ll.companieSelected.enable_youtube_api
          "
        />
      </b-col>
      <b-col cols="2">
        <b-form-input
          placeholder="Título"
          autocomplete="off"
          v-model="ll.url_title"
          v-if="companies.some((s) => s.enable_youtube_api)"
        />
      </b-col>
      <b-col cols="2">
        <v-select
          v-if="statusVisibilities.length > 0"
          v-model="ll.statusVisibilitySelected"
          :options="statusVisibilities"
          autocomplete="off"
          :clearable="false"
          placeholder="Status pós transmissão"
        />
      </b-col>

      <b-col cols="1">
        <b-button
          variant="danger"
          class="btn-icon"
          @click="removeLink(ll)"
          style="float: right"
        >
          <feather-icon size="20" icon="Trash2Icon" />
        </b-button>
      </b-col>
    </b-row>

    <!-- <div
      class="py-1 d-flex"
      v-if="
        links.length > 0 &&
        companies.filter((s) => s.enable_youtube_api).length <= 0
      "
    >
      <b-form-checkbox
        class="custom-control-success"
        name="check-button"
        v-model="appointment.available_youtube"
        switch
      >
        <span class="switch-icon-left">
          <feather-icon icon="CheckIcon" />
        </span>
        <span class="switch-icon-right">
          <feather-icon icon="XIcon" />
        </span>
      </b-form-checkbox>
      <strong>Transmissão Pública ?</strong>
    </div> -->

    <b-row class="mt-3">
      <b-col cols="12">
        <b-button variant="info" @click="onClickNext">
          <span class="mr-25 align-middle">Próximo</span>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import _companyService from "@/services/company-service";
import _appointmentService from "@/services/appointment-service";
export default {
  props: {
    appointment: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      isloading: false,
      validate: true,
      links: [],
      companies: [],
      statusVisibilities: [],
    };
  },
  created() {
    // links do youtube
    if (this.appointment.links && this.appointment.links.length > 0) {
      this.links = this.appointment.links;
    }

    // tipos de transmissão disponiveis do JOBTYPE
    if (this.appointment.jobTypeSelected) {
      if (
        this.appointment.jobTypeSelected.jobTransmission &&
        this.appointment.jobTypeSelected.jobTransmission.length > 0
      ) {
        this.jobTypeTransmissionList =
          this.appointment.jobTypeSelected.jobTransmission.map((m) => {
            return {
              label: m.transmissionType.name,
              value: m.transmissionType.id,
              amount: m.amount,
            };
          });
      }
    }
  },
  mounted() {
    this.getCompanies();
    this.getVisibility();
  },
  methods: {
    addLink() {
      let amountPerType = [];
      // links

      // apenas a quantidade permitida no jobtype
      if (this.jobTypeTransmissionList) {
        const sumAmount = this.jobTypeTransmissionList.reduce(function (s, a) {
          return s + a.amount;
        }, 0);

        if (this.links.length < sumAmount) {
          // links atuais a serem descontados
          this.links.forEach(function (s, i) {
            amountPerType.push({
              transmission_type_id: s.jobTypeTransmissionSelected.value,
              transmission_type_name: s.jobTypeTransmissionSelected.label,
              amount: -1,
            });
          });

          // links possíveis por type
          this.jobTypeTransmissionList.forEach(function (s, i) {
            amountPerType.push({
              transmission_type_id: s.value,
              transmission_type_name: s.label,
              amount: s.amount,
            });
          });

          // links restantes
          amountPerType = Object.values(
            amountPerType.reduce(
              (r, o) => (
                r[o.transmission_type_id]
                  ? (r[o.transmission_type_id].amount += o.amount)
                  : (r[o.transmission_type_id] = { ...o }),
                r
              ),
              {}
            )
          ).filter((f) => f.amount > 0);

          this.links.push({
            companieSelected: this.companies.filter(
              (f) => f.value === this.appointment.projectSelected.company_id
            )[0],
            companies: this.companies.filter(
              (f) => f.value === this.appointment.projectSelected.company_id
            ),
            id: this.$utils.getGuidId(),
            url: "",
            url_title: "",
            jobTypeTransmissionSelected: {
              label: amountPerType.at(0).transmission_type_name,
              value: amountPerType.at(0).transmission_type_id,
            },
            jobTypeTransmissionSelected: {
              label: amountPerType.at(0).transmission_type_name,
              value: amountPerType.at(0).transmission_type_id,
            },
            statusVisibilitySelected: this.statusVisibilities[0],
          });
        }
      }
    },
    removeLink(ll) {
      this.links = this.links.filter((f) => f.id !== ll.id);
      this.appointment.links = this.links;
    },
    getCompanies() {
      _companyService
        .showAll()
        .then((res) => {
          this.companies = res.content.map((m) => {
            return {
              enable_youtube_api: m.enable_youtube_api,
              label: m.name,
              value: m.id,
            };
          });
        })
        .catch((error) => this.$utils.toastError("Notificação", error));
    },
    getVisibility() {
      _appointmentService
        .getStatusVisibility()
        .then((res) => {
          this.statusVisibilities = res;
        })
        .catch((error) => this.$utils.toastError("Notificação", error));
    },
    onClickNext() {
      this.validateLinks();

      if (this.links && this.links.length > 0 && this.validate == true) {
        this.appointment.links = this.links;

        this.appointment.appointmentLinks = this.links.map((m) => {
          return {
            url: m.url,
            url_title: m.url_title,
            company_id: m.companieSelected.value,
            transmission_type_id: m.jobTypeTransmissionSelected.value,
            visibility: m.statusVisibilitySelected.value,
          };
        });
      }
      if (this.validate == true) this.$emit("next", this.appointment);
    },
    validateLinks() {
      this.validate = true;
      // array de urls
      const _lnkVV = this.links.filter((f) => f.url).map((m) => m.url);
      // array de titulos
      const _titVV = this.links.map((m) => m.url_title);
      // array sem duplicados
      const unique = _lnkVV.filter((v, i, a) => a.indexOf(v) === i);
      // array sem vazios
      const validLinks = _lnkVV.filter((l) => l != "");
      const validTit = _titVV.filter((l) => l != "");
      // array de controle do jobtype
      let amountPerType = [];
      let amountPerTypeResult = [];

      if (unique.length < _lnkVV.length) {
        this.$utils.toastError("Notificação", "Não pode usar links iguais.");
        this.validate = false;
        return;
      } 
      // else if (validTit.length < _titVV.length) {
      //   this.$utils.toastError("Notificação", "Campo de Título vazio");
      //   this.validate = false;
      //   return;
      // }

      // links possíveis por type
      this.jobTypeTransmissionList.forEach(function (s, i) {
        amountPerType.push({
          transmission_type_id: s.value,
          transmission_type_name: s.label,
          amount: s.amount,
        });
      });

      this.links.forEach((fe) => {
        if (
          !fe.jobTypeTransmissionSelected ||
          !fe.jobTypeTransmissionSelected.value
        ) {
          this.$utils.toastError(
            "Notificação",
            "Selecione o tipo de transmissão."
          );
          this.validate = false;
        } else if (!fe.companieSelected || !fe.companieSelected.value) {
          this.$utils.toastError("Notificação", "Selecione a empresa.");
          this.validate = false;
        } else {
          fe.transmission_type_id = fe.jobTypeTransmissionSelected.value;
          fe.company_id = fe.companieSelected.value;
          if (!fe.company_id) {
            fe.company_id = fe.companieSelected[0].value;
          }
          if (
            !fe.transmission_type_id &&
            fe.jobTypeTransmissionSelected &&
            fe.jobTypeTransmissionSelected.length > 0
          ) {
            fe.transmission_type_id = fe.jobTypeTransmissionSelected[0].value;
          }

          // links atuais a serem descontados
          amountPerType.push({
            transmission_type_id: fe.jobTypeTransmissionSelected.value,
            transmission_type_name: fe.jobTypeTransmissionSelected.label,
            amount: -1,
          });

          // links com somatorio por type negativo, extrapolam a quantidade possível
          amountPerTypeResult = Object.values(
            amountPerType.reduce(
              (r, o) => (
                r[o.transmission_type_id]
                  ? (r[o.transmission_type_id].amount += o.amount)
                  : (r[o.transmission_type_id] = { ...o }),
                r
              ),
              {}
            )
          ).filter((f) => f.amount < 0);

          if (amountPerTypeResult.length > 0) {
            this.$utils.toastError(
              "Notificação",
              "O tipo de agendamento não comporta essa quantidade de '" +
                amountPerTypeResult.at(0).transmission_type_name +
                "'(s) selecionados."
            );
            this.validate = false;
          }
        }
      });
    },
  },
};
</script>