<template>
  <b-row>
    <b-col md="7" class="text-center">
      <b-form-group label="Calendário">
        <b-calendar
          v-model="start"
          locale="pt-BR"
          :min="new Date()"
          :max="null"
          hide-header
          block
          class="mt-1"
          @context="onContext"
          today-variant="info"
          selected-variant="success"
        />
      </b-form-group>
    </b-col>
    <b-col class="mt-sm-3" cols="6" md="2" v-if="start">
      <b-form-group label="Escolha um horário">
        <b-form-input
          type="time"
          min="00:00"
          max="23:59"
          step="900"
          v-model="hour"
        />
      </b-form-group>
      <b-form-group label="Duração Recomen." v-if="appointment.appointment_pending">
        <flat-pickr
          v-model="appointment.duration_pending"
          placeholder="Duração"
          class="form-control"
          :config="{
            enableTime: true,
            noCalendar: true,
            dateFormat: 'H:i',
            time_24hr: true,
          }"
        />
      </b-form-group>
    </b-col>
    <b-col cols="12">
      <b-button
        variant="info"
        @click="onClickNext"
      >
        <span class="mr-25 align-middle">Próximo</span>
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import { TabContent } from "vue-form-wizard";
import { BCalendar } from "bootstrap-vue";
import moment from "moment";

export default {
  props: {
    appointment: {
      type: Object,
      default: () => null,
    },
  },
  components: {
    BCalendar,
    TabContent,
  },
  data() {
    return {
      start: null,
      hour: null,
      hourValid: false,
    };
  },
  created() {
    if (this.appointment.start) {
      this.start = this.appointment.start.split("T")[0];
      this.hour = this.appointment.start.split("T")[1];
    }
  },
  methods: {
    onContext(ctx) {},
    isValidTime() {
      var _date = new Date();
      var _hora = _date.getHours();
      var _minutes = _date.getMinutes();
      const dateFormat = moment(_date).format("YYYY-MM-DD");

      this.hourValid = true;
      if (moment(this.start).isSame(dateFormat)) {
        var minTime = moment(`${_hora}:${_minutes}`, "hh:mm");
        var maxTime = moment("23:59", "hh:mm");
        this.hourValid = moment(this.hour, "hh:mm").isBetween(minTime, maxTime);
      }
    },
    onClickNext() {
      if(this.start && this.hour) {
        this.$emit('next', `${this.start}T${this.hour}`)
      }
    }
  },
};
</script>