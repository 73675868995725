import HttpService from '../HttpService'

const _http = new HttpService()
export default {
  show: (page, options = null) => {
    let _parans = `?page=${page - 1}`;
    if (options) {
      if (options.search)
        _parans += `&search=${options.search}`;
      if (options.id)
        _parans += `&data.id=${options.id}`;
      if (options.teacher_id)
        _parans += `&data.teacher_id=${options.teacher_id}`;
      if (options.event_id)
        _parans += `&data.event_id=${options.event_id}`;
      if (options.course_id)
        _parans += `&data.course_id=${options.course_id}`;
      if (options.project_id)
        _parans += `&data.project_id=${options.project_id}`;
      if (options.status_id || options.status_id == 0)
        _parans += `&data.status_id=${options.status_id}`;
      if (options.start_date)
        _parans += `&data.start=${options.start_date}`;
      if (options.end_date)
        _parans += `&data.end=${options.end_date}`
      if (options.orderByKey)
        _parans += `&order_by=${options.orderByKey}`;
      if (options.ascOrDes)
        _parans += '&asc';
    }
    return _http.get(`/api/audio-visual/appointment-requests${_parans}`)
  },
  create: (payload) => {
    return _http.post('/api/audio-visual/appointment-requests', payload)
  },
  find: (id) => {
    return _http.get(`/api/audio-visual/appointment-requests/${id}`)
  }
}