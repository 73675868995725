<template>
  <div v-if="appointment">
    <b-row class="mt-1">
      <b-col cols="12" class="mb-2" v-if="!projectSelected">
        <!-- <h3 class="mb-0">Projetos</h3> -->
        <small class="text-muted">
          Escolha um projeto para o
          {{ appointment.id > 0 ? "Reagendamento" : "Agendamento" }}.
        </small>
      </b-col>
      <b-col cols="12" class="mb-2" v-else>
        <h5 class="mb-0 text-uppercase">
          {{ projectSelected.name }}
        </h5>
        <div v-if="projectSelected.company">
          <b-badge :style="`background: ${projectSelected.companycolor}`">
            <feather-icon icon="HomeIcon" size="21" />
          </b-badge>
          <small>
            {{ projectSelected.company }}
          </small>
        </div>
      </b-col>
      <b-col md="6" v-if="appointment.id <= 0">
        <b-form-group>
          <b-overlay
            :variant="skin"
            :show="isloading"
            spinner-variant="primary"
            spinner-type="grow"
          >
            <vue-autosuggest
              v-model="queryProject"
              :suggestions="projects"
              :input-props="inputProps"
              :section-configs="autoSugestsConfigs"
              @input="getAutoCompleteProjects"
            >
              <template slot-scope="{ suggestion }">
                <div v-if="suggestion.item.valid">
                  <h5 class="mb-0 text-uppercase">
                    {{ suggestion.item.name }}
                  </h5>
                  <div v-if="suggestion.item.company">
                    <b-badge
                      :style="`background: ${suggestion.item.companycolor}`"
                      ><feather-icon icon="HomeIcon" size="21"
                    /></b-badge>
                    <small>
                      {{ suggestion.item.company }}
                    </small>
                  </div>
                </div>
              </template>
            </vue-autosuggest>
          </b-overlay>
        </b-form-group>
      </b-col>
    </b-row>

    <!-- jobTypelist -->
    <b-row align-v="center">
      <b-col cols="10" md="6" class="my-1">
        <v-select v-model="jobTypeSelected" :options="jobTypelist" />
      </b-col>
      <b-col cols="2" md="1" v-if="jobTypeSelected">
        <feather-icon size="20" icon="InfoIcon" id="tooltip-button-2" />
        <b-tooltip target="tooltip-button-2" placement="right" triggers="hover">
          <div
            v-if="
              jobTypeSelected.available_for_events ||
              jobTypeSelected.available_for_lesson
            "
            class="tooltips-itens"
          >
            Disponível para:
            <b-badge
              variant="light-warning"
              v-if="jobTypeSelected.available_for_events"
              >Eventos</b-badge
            >
            <b-badge
              variant="light-warning"
              v-if="jobTypeSelected.available_for_lesson"
              >Aulas</b-badge
            >
          </div>

          <div
            v-if="
              jobTypeSelected.jobFormats &&
              jobTypeSelected.jobFormats.length > 0
            "
            class="tooltips-itens"
          >
            Tipos de Formato:
            <b-badge
              variant="light-success"
              v-for="(jf, index) in jobTypeSelected.jobFormats"
              :key="index"
              style="margin-right: 3px"
              >{{ jf.formatType.name }}</b-badge
            >
          </div>

          <div
            v-if="
              jobTypeSelected.jobProducts &&
              jobTypeSelected.jobProducts.length > 0
            "
            class="tooltips-itens"
          >
            Tipos de Produto:
            <b-badge
              variant="light-info"
              v-for="(jp, index) in jobTypeSelected.jobProducts"
              :key="index"
              style="margin-right: 3px"
              >{{ jp.products.name }}</b-badge
            >
          </div>

          <div
            v-if="
              jobTypeSelected.jobTransmission &&
              jobTypeSelected.jobTransmission.length > 0
            "
            class="tooltips-itens"
          >
            Tipos de Transmissão:
            <b-badge
              variant="light-danger"
              v-for="(jt, index) in jobTypeSelected.jobTransmission"
              :key="index"
              style="margin-right: 3px"
            >
              {{ jt.transmissionType.name }} - {{ jt.amount }}
            </b-badge>
          </div>

          <div class="tooltips-itens">
            Obrigatório Upload Material:
            <b>
              {{ jobTypeSelected.required_upload_material ? "Sim" : "Não" }}
            </b>
          </div>

          <div class="tooltips-itens">
            Aceita extrapolação de horário:
            <b>
              {{ jobTypeSelected.accept_time_extrapolation ? "Sim" : "Não" }}
            </b>
          </div>

          <div class="tooltips-itens" v-if="jobTypeSelected.jobTypePeriod">
            Período:
            <b> {{ jobTypeSelected.jobTypePeriod.name }} </b>
          </div>

          <div class="tooltips-itens">
            Aceita material gravado:
            <b>
              {{ jobTypeSelected.accept_recordings ? "Sim" : "Não" }}
            </b>
          </div>

          <div class="tooltips-itens">
            Quantidade de operadores necessários:
            <b> {{ jobTypeSelected.number_of_operators }} </b>
          </div>

          <div class="tooltips-itens">
            Tempo de preparação:
            <b>
              {{ jobTypeSelected.preparation_time_minute }}
              (Minutos)
            </b>
          </div>

          <div class="tooltips-itens">
            Mínimo de estúdio:
            <b> {{ jobTypeSelected.min_studios }} </b>
          </div>
        </b-tooltip>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <b-button variant="info" @click="onClickNext">
          <span class="mr-25 align-middle">Próximo</span>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import _appointmentService from "@/services/appointment-service";
import _jobTypeService from "@/services/audiovisual/job-type-service";
import useAppConfig from "@core/app-config/useAppConfig";
export default {
  setup() {
    const { skin } = useAppConfig();
    return {
      skin,
    };
  },
  props: {
    appointment: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      isloading: false,
      jobTypeSelected: null,
      jobTypelist: [],

      queryProject: "",
      projects: [],
      projectSelected: null,

      autoSugestsConfigs: {
        projects: {
          limit: 20,
          onSelected: (selected) => {
            if (selected.item.valid) {
              this.projectSelected = selected.item;
            }
          },
        },
      },
      inputProps: {
        id: "autocompanies__input_ajax",
        placeholder: "Digite o nome do Projeto, empresa...",
        class: "form-control",
        name: "ajax",
      },
    };
  },
  created() {
    this.jobTypeSelected = this.appointment.jobTypeSelected;
    this.projectSelected = this.appointment.projectSelected;
  },
  mounted() {
    this.getJobTypesList();
  },
  methods: {
    getAutoCompleteProjects() {
      if (this.queryProject && this.queryProject.length >= 3) {
        setTimeout(() => {
          if (!this.isloading) {
            this.isloading = true;
            _appointmentService
              .showProjects(this.queryProject, this.appointment.start)
              .then((res) => {
                this.projects = [{ name: "projects", data: res.content }];
              })
              .catch((error) => this.$utils.toastError("Notificação", error))
              .finally(() => (this.isloading = false));
          }
        }, 500);
      }
    },
    getJobTypesList() {
      _jobTypeService
        .showJobTypeAll()
        .then((res) => {
          this.jobTypelist = res.content.map((m) => {
            return {
              label: m.name,
              value: m.id,
              ...m,
            };
          });
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.isloading = false));
    },
    onClickNext() {

      if (this.jobTypeSelected.jobFormats.length <= 0) {
        this.$utils.toastError(
          "Tipo Agendamento",
          `Nenhum formato cadastrado para ${this.jobTypeSelected.name}`
        );
        return;
      }

      // se alterar o projeto ou tipo, recomeça todos os passos;
      if( this.appointment.id == 0 &&
          ((this.jobTypeSelected != null && 
            this.jobTypeSelected != this.appointment.jobTypeSelected) 
          || 
           (this.projectSelected != null &&
            this.projectSelected != this.appointment.projectSelected))
        )
      {
        let appNew = {
        //this.appointment = {
          id: 0,
          name: null,
          description: null,
          start: this.appointment.start,
          send_notification: true,
          accept_recording: false,
          available_youtube: true,
          recurrenceItens: [],
          studios: [],
          teachers: [],
        }

        appNew.jobTypeSelected = this.jobTypeSelected;
        appNew.projectSelected = this.projectSelected;

        this.$emit("next", appNew);
        
        return;
      }

      this.appointment.jobTypeSelected = this.jobTypeSelected;
      this.appointment.projectSelected = this.projectSelected;

      // reagendamento
      if (this.appointment.id > 0) {
        // 1 - evento, 2 - aula
        if (this.appointment.type === 1) {
          if (
            (this.appointment.recording_all_events ||
              this.appointment.accept_recording) &&
            !this.jobTypeSelected.available_for_events
          ) {
            // agendamento do tipo evento, e novo jobtype não tem opção de evento.
            this.$utils.toastError(
              "REAGENDAMENTO!!!",
              `O tipo de agendamento >> ${this.jobTypeSelected.label} << escolhido, não aceita EVENTO. Seu agendamento atual é um evento.`
            );
            return;
          }
        }
      }
      this.$emit("next", this.appointment);
    },
  },
};
</script>

<style lang="scss">
.tooltip-inner {
  max-width: 500px !important;
  text-align: left !important;
}

.tooltips-itens {
  margin: 2px;
}
</style>