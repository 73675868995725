import HttpService from '../HttpService'

const _http = new HttpService()
export default {
  show: (page, options = null) => {
    let _parans = `?page=${page - 1}`;
    if (options) {
      if(options.search) {
        _parans += `&search=${options.search}`;
      }
      if (options.orderByKey) {
        _parans += `&order_by=${options.orderByKey}`
      }
      if (options.ascOrDes) {
        _parans += '&asc'
      }
    }
    return _http.get(`/api/audio-visual/job-type${_parans}`)
  },
  find: (id) => {
    //teste commit
    return _http.get(`/api/audio-visual/job-type/${id}`)
  },
  create: (payload) => {
    return _http.post('/api/audio-visual/job-type', payload)
  },
  update: (payload) => {
    return _http.put('/api/audio-visual/job-type', payload)
  },
  delete: (id) => {
    return _http.delete(`/api/audio-visual/job-type/${id}`)
  },
  showJobTypeAll: () => {
    return _http.get(`/api/audio-visual/job-type/job-type-all`)
  },
  autoComplete: (search = "") => {
    return _http.get(`/api/audio-visual/job-type/auto-complete?search=${search}`)
  },
  getSimplifiedJobTypes: () => {
    return _http.get('/api/audio-visual/job-type/get-simplified-job-types')
  }
}